import React from 'react';
import { Container, Row, Col, } from 'react-bootstrap';
import { FaPhoneVolume } from "react-icons/fa6";
import { MdOutlineEmail } from "react-icons/md";
import { BiLogoFacebookCircle } from "react-icons/bi";
import Imgxing from '../../assets/xingImg.png'
import Imglinkidin from '../../assets/Linkdin.png'
import Imgyoutube from '../../assets/youtube.png'
import Imgkaggle from '../../assets/kaggleImg.png'
import Imgfacebook from '../../assets/Facebook.png'
import { Link } from 'react-router-dom';
import './HfFooter.css'

const HfFooter = () => {
  return (
    <footer className='hfFooter-wrapper' id='contact-us-section'>
      <Container className='hfFooter-container'>
        <Row className="justify-content-center " style={{ marginLeft: 'auto' }}>
          <Col sm={12} lg={4} >
            <div className='footer-column-div'>
              <b>How to reach us</b>
              <ul>
                <li><a href="tel:02135 -663939" className="footer-link"><FaPhoneVolume /> 02135 -663939 (MHP)</a></li>
                <li><a href="tel:02135 -678304" className="footer-link"><FaPhoneVolume /> 02135 -678304 (IFP)</a></li>
                <li><a href="tel:02135 -352920" className="footer-link"><FaPhoneVolume /> 02135 -352920 /908 (SHP)</a></li>
                <li><a href="mailto:inp@hansa-flex.com" className="footer-link"><MdOutlineEmail /> inp@hansa-flex.com</a></li>


                {/* <li><span style={{ fontSize: '1rem' }}><FaPhoneVolume /></span> 02135 -663939 (MHP)</li>
                <li><span style={{ fontSize: '1rem' }}><FaPhoneVolume /></span> 02135 -678304 (IFP)</li>
                <li><span style={{ fontSize: '1rem' }}><FaPhoneVolume /></span> 02135 -352920 /908 (SHP)</li>
                <li> <span style={{ fontSize: '1.2rem' }}><MdOutlineEmail /></span>inp@hansa-flex.com</li> */}

              </ul>
            </div>

          </Col>

          <Col sm={12} lg={4}>
            <div className='footer-column-div'>
              <b>About HANSA‑FLEX</b>
              <ul>
                <li><Link to="/management" className="footer-link">Management</Link></li>
                <li><Link to="/certificates" className="footer-link">Certificates</Link></li>
                {/* <li><Link to="/careers" className="footer-link">Careers</Link></li> */}
              </ul>
            </div>
          </Col>


          {/* <Col xs={4} lg={3}>
          <div className='footer-column-div'>
          <b>Service</b>
          <ul>
                <li>Mobile rapid hydraulic service</li>
                <li>Fluid management</li>
                <li>Assembly and installation</li>
                <li>Hydarulic cylinder repair</li>
                <li>Preventive maintenance</li>
            </ul>
          </div>
          </Col> */}

          <Col sm={12} lg={4}>
            <div className='footer-column-div'>
              <b>Popular Categories</b>
              <ul>
                <li><Link to="/hoses-and-hose-lines" className="footer-link">Products</Link></li>
                <a href="https://my.hansa-flex.in/en_GB/login" target="_blank" rel="noopener noreferrer" className="footer-link">X-CODE Manager</a>


              </ul>
            </div>
          </Col>

        </Row>

        <Row>
          <Col className="d-flex justify-content-center align-items-center">
            <b>Follow us</b>
          </Col>
        </Row>


        <Row className="custom-3row ">

          {/*<Col><div className="circle-col"><img src={Imgkaggle} alt="" /></div></Col>
          <Col><div className="circle-col"><img src={Imgxing} alt="" /></div></Col> */}
          <Col><div className="circle-col"> <a href="https://www.linkedin.com/company/hansa-flex-india-private-limited/?originalSubdomain=in" target="_blank" rel="noopener noreferrer"><div>< img src={Imglinkidin} alt="" /></div></a></div></Col>
          <Col><div className="circle-col"><a href="https://www.youtube.com/@HANSAFLEXAG" target="_blank" rel="noopener noreferrer"><div><img src={Imgyoutube} alt="" /></div></a></div></Col>
          <Col><div className="circle-col"><a href="https://www.facebook.com/people/Hansa-Flex-India-Pvt-Ltd/61552307244391/" target="_blank" rel="noopener noreferrer"><div><img src={Imgfacebook} alt="" /></div></a></div></Col>

        </Row>

        <Row className="line-row">
          <Col className="line-col"></Col>
        </Row>

        <div className='HfFooter-link-div'>
          <a className="hf-footer-link" href="">© Copyright All Rights Reserved</a><span>  |  </span>
          <Link to="/hansa-flex-india-privacy-policy" className="footer-link">Privacy Policy</Link>

          {/*<a className="hf-footer-link" href="">GT&C</a><span>  |  </span>
          <a className="hf-footer-link" href="">Purchasing and logistics</a><span>  |  </span>
        <a className="hf-footer-link" href="">Data protection</a>*/}

        </div>



      </Container>
    </footer >

  );
};

export default HfFooter;
