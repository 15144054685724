import React, { useEffect } from 'react';
import CenterStage from '../../Components/CenterStage/CenterStage';
import imghistory from '../../assets/History/imgHistory.jpg'
import SectionHeader from '../../Components/SectionHeader/SectionHeader';
import img1962 from '../../assets/60YearHistory/img1962.jpg'
import img1989 from '../../assets/60YearHistory/img1989.jpg'
import img2009 from '../../assets/60YearHistory/img2009.jpg'
import ImageText from '../../Components/ImageText/ImageText';
import imgMark from '../../assets/mark.png'
import FeatureCardCarousel from '../../Components/FeaturesCardCarousal/FeatureCardCarousal';
import ImageCard from '../../Components/ImageCard/ImageCard';
import imgtraining from '../../assets/60YearHistory/imgTraining.png';
import imgRetirement from '../../assets/60YearHistory/imgRetirement.jpg';
import imgsaddle from '../../assets/60YearHistory/imgSaddle.jpg';
import { Container, Row, Col } from 'react-bootstrap';
function SixtyYearOfHansaflex() {


  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top on component mount
  }, []);


  const img1962history = {
    image: img1962,
    title: 'The early years 1962-1989',
    description: 'In the early 1960s, Germany faced challenges in finding hydraulic components. Joachim Armerding recognized the opportunity and began assembling hydraulic hose lines in his garage. Personally delivering them to customers, he laid the groundwork for HANSA‑FLEX. By 1988, with Günter Buschmanns partnership and the founders sons involvement, the company expanded to 21 branches nationwide.'
  }

  const img1989history = {
    image: img1989,
    title: 'Strong growth and internationalisation 1989-2009 ',
    description: 'During the era of German reunification and the fall of the Iron Curtain, HANSA‑FLEX experienced rapid growth. It established its first foreign branch in Elst, the Netherlands, in 1989, followed by expansions across Europe. Under new management in 1995, it expanded globally, with branches in Turkey, the Americas, Africa, and Asia by 2009 '
  }

  const img2009history = {
    image: img2009,
    title: 'In the age of digitalisation – from 2009 to today',
    description: 'Amid global digitalization, HANSA‑FLEX enhances its investment in digital infrastructure and services. The HANSA‑FLEX app, online shop, and My.HANSA‑FLEX customer portal deliver substantial value. New international branches emerge in countries like Australia, the UK, and India. In 2019, the PHOENIX digitalization initiative launches, and by 2020, HANSA‑FLEX AG serves over 150,000 customers worldwide.'
  }

  //  FeatureCardData

  const featuresData = [
    {
      title: "4208 employees",
      description: "We work with great passion every day for our customers",
      image: imgMark
    },
    {
      title: "449 locations",
      description: "We offer an all-round service for hydraulics in every branch – fast & individual.",
      image: imgMark
    },
    {
      title: "401 service vehicles",
      description: "The mobile Rapid Hydraulic Service is available around the clock, 365 days a year.",
      image: imgMark
    },
    {
      title: "41 countries",
      description: "Our regional branches work closely with our headquarters in Bremen",
      image: imgMark
    }
    // Add more objects for additional FeaturesCard data
  ];

  return (
    <div>
      <div>
        <CenterStage
          imageSrc={imghistory}
          title={"PASSIONATE ABOUT CUSTOMER CARE SINCE 1962"}
          paragraph={"Bremen businessman Joachim Armerding founded the HANSA‑FLEX company 60 years ago in the garage of his house near Bremen. His business concept was timely, his motto as simple as it was ingenious: Fulfil the wishes of your customers more rapidly, more flexibly and more fully than all your competitors and never forget the human side and enjoyment of your work. HANSA‑FLEX has grown with this passion, which is as evident today as it was back then."}
        />
      </div>

      <div className='div-gray'>
        <SectionHeader
          title={"OUR DRIVE IS IN OUR DNA"}
          subtitle={"The values and principles of our Mission Statement exemplify HANSA‑FLEX’s commitment to its customers, guiding us in our daily operations both internal and external."}
        />

        <div >
          <br />
          <ImageText data={img1962history} left={false} /> <br /><br />
        </div>

        <div >
          <br />
          <ImageText data={img1989history} /> <br /><br />
        </div>

        <div >
          <br />
          <ImageText data={img2009history} left={false} /> <br /><br />
        </div>

      </div>


      {/* FeatureCardCarousal */}

      <div>
        <FeatureCardCarousel featuresData={featuresData} />
      </div>


      <div className='div-gray'>
        <SectionHeader
          title={"PASSION TAKES MANY FORMS"}
          subtitle={"Well-trained, ready to help, team spirited and professional – that’s how you find the employees of HANSA-FLEX in their work environment. In our series “People at HANSA-FLEX”, they show something of their lives outside work."}
        />

        <Container className="custom-container">
          <Row>
            <Col sm={12} md={6} lg={4} ><ImageCard image={imgtraining} title="Training at HANSA-FLEX – the first 60 days" desc={"At HANSA-FLEX, Warehouse Logistics Specialist and the two-year variant Warehouse Specialist are the most common training courses, with just under 50% of our trainees learning this profession."} /></Col>
            <Col sm={12} md={6} lg={4} ><ImageCard image={imgRetirement} title="60 days until retirement" desc="After 25 years at HANSA-FLEX, Falk Simon is now about to take his well-deserved retirement. He looks back over the last 60 days and reports on his time at HANSA-FLEX" /></Col>
            <Col sm={12} md={6} lg={4} ><ImageCard image={imgsaddle} title="Firmly in the saddle" desc="Whether it&apos;s show jumping, dressage, riding in the Wadden Sea, training horses or coaching young riders: for Marie Abel equestrian sport has been an integral part of her life since childhood." /></Col>

          </Row>
        </Container>

      </div>



    </div>
  );
}

export default SixtyYearOfHansaflex;
