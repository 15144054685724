import React, { useEffect, useState } from 'react';
import './CircleCarousel.css'; // Updated CSS import

const ThreeDImageCarousel = ({ items }) => {
    const [currentSlide, setCurrentSlide] = useState(0);
    const [isHovered, setIsHovered] = useState(false); // Track hover state

    // Handle the next and previous slide logic
    const nextSlide = () => {
        setCurrentSlide((prev) => (prev + 1) % items.length);
    };

    const prevSlide = () => {
        setCurrentSlide((prev) => (prev - 1 + items.length) % items.length);
    };

    // Auto-rotate every 3 seconds, unless hovered
    useEffect(() => {
        if (!isHovered) {
            const interval = setInterval(() => {
                nextSlide(); // Automatically move to the next slide every 3 seconds
            }, 3000); // 3000 ms = 3 seconds

            // Clear the interval when the component unmounts or if hover state changes
            return () => clearInterval(interval);
        }
    }, [currentSlide, items.length, isHovered]);

    return (
        <section id="threeD-slider"
            onMouseEnter={() => setIsHovered(true)}  // Stop auto-rotation on hover
            onMouseLeave={() => setIsHovered(false)} // Resume auto-rotation on leave
        >
            {items.map((item, index) => {
                const position = ((index - currentSlide + items.length) % items.length);

                // Apply styles dynamically based on the position (previous, current, or next)
                let transformStyle = 'translateX(-100%) scale(0)'; // hide other images
                let opacity = 0; // make hidden slides transparent
                let blur = 'none'; // clear blur for current image
                let zIndex = 0; // default z-index

                // Handle previous image (blurred and on the left)
                if (position === 1) {
                    transformStyle = 'translateX(-80%) scale(0.5)';
                    opacity = 1;
                    blur = 'blur(5px)';
                    zIndex = 1;
                }

                // Handle current image (center, no blur)
                if (position === 0) {
                    transformStyle = 'translateX(0) scale(1)';
                    opacity = 1;
                    blur = 'none';
                    zIndex = 2;
                }

                // Handle next image (blurred and on the right)
                if (position === items.length - 1) {
                    transformStyle = 'translateX(80%) scale(0.5)';
                    opacity = 1;
                    blur = 'blur(5px)';
                    zIndex = 1;
                }

                return (
                    <div
                        key={`slide-${index}`}
                        className={`threeD-slide`}
                        style={{
                            backgroundImage: `url(${item.image})`,
                            transform: transformStyle,
                            opacity: opacity,
                            filter: blur,
                            zIndex: zIndex,
                            transition: 'transform 1s ease-in-out, opacity 1s ease-in-out', // Smooth rotation
                        }}
                    >
                        {item.title && item.description && <div className="threeD-carousel-caption">
                            <h3>{(item.title)}</h3>
                            <p>{(item.description)}</p>
                        </div>}
                    </div>
                );
            })}
            <button onClick={prevSlide} className="prev-btn">
                <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" class="bi bi-chevron-compact-left" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M9.224 1.553a.5.5 0 0 1 .223.67L6.56 8l2.888 5.776a.5.5 0 1 1-.894.448l-3-6a.5.5 0 0 1 0-.448l3-6a.5.5 0 0 1 .67-.223" />
                </svg>
            </button>
            <button onClick={nextSlide} className="next-btn">
                <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" class="bi bi-chevron-compact-right" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M6.776 1.553a.5.5 0 0 1 .671.223l3 6a.5.5 0 0 1 0 .448l-3 6a.5.5 0 1 1-.894-.448L9.44 8 6.553 2.224a.5.5 0 0 1 .223-.671" />
                </svg>
            </button>
        </section>
    );
};

export default ThreeDImageCarousel;
