import React, { useEffect } from 'react'
import CenterStage from '../../../Components/CenterStage/CenterStage'
import SectionHeader from '../../../Components/SectionHeader/SectionHeader'
import { Col, Container, Row } from 'react-bootstrap'
import ImageCard from '../../../Components/ImageCard/ImageCard'
import FeatureCardCarousel from '../../../Components/FeaturesCardCarousal/FeatureCardCarousal'
import imgMark from '../../../assets/mark.png'
import ImageText from '../../../Components/ImageText/ImageText'
import HFTabs from '../../../Components/HfTabs/HfTabs'

import Stage from '../../../assets/Products/HydraulicConnectionTechnology/Flanges/Stage.jpg'
import SAE from '../../../assets/Products/HydraulicConnectionTechnology/Flanges/SAE.jpg'
import CetopFlange from '../../../assets/Products/HydraulicConnectionTechnology/Flanges/CetopFlange.jpg'
import PumpFlange from '../../../assets/Products/HydraulicConnectionTechnology/Flanges/PumpFlange.jpg'
import PurchaseFlange from '../../../assets/Products/HydraulicConnectionTechnology/Flanges/PurchaseFlange.jpg'
import Compliment from '../../../assets/Products/HydraulicConnectionTechnology/Flanges/Compliment.jpg'
import Quality from '../../../assets/Products/HydraulicConnectionTechnology/Flanges/Quality.jpg'
import Universal from '../../../assets/Products/HydraulicConnectionTechnology/Flanges/Universal.jpg'
import leakProof from '../../../assets/Products/HydraulicConnectionTechnology/Flanges/eakProof.jpg'
import SpecialFittings from '../../../assets/Products/HydraulicConnectionTechnology/Flanges/SpecialFittings.jpg'
import MadeToMeasure from '../../../assets/Products/HydraulicConnectionTechnology/Flanges/MadeToMeasure.jpg'

const Flanges = () => {

    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
    }, []);

    const Data1 = {
        title: 'SAE flange',
        description: 'SAE flanges are available in two pressure classes: 3000 PSI in nominal diameters ½” to 5” and 6000 PSI from ½” to 3”. All SAE high-pressure flanges are manufactured in steel or stainless steel designation number 1.4404.',
        listItems: ["Working pressure up to 420 bar", "For standard & high-pressure applications", "Untreated surface or galvanised zinc plated"],
        buttonName: "VIEW ALL",
        buttonLink: "https://shop.hansa-flex.in/en_GB/flanges/sae-high-pressure-flanges/c/webcat_HF_FLA_0460",
        image: SAE,
    }

    const Data2 = {
        title: 'ISO 6164 and CETOP flanges',
        description: 'We can provide you with our HANSA‑FLEX ISO 6164 flanges and CETOP flange connections in nominal diameters of ½” to 5”. All ISO 6164 and CETOP flanges are available in steel designation number 1.4404.',
        listItems: ["Working pressure up to 420 bar", "Nominal diameter between DN 13 (1/2”) and DN 127 (5”)", "Stainless steel and alternative sealing materials on request"],
        image: CetopFlange
    }

    const Data3 = {
        title: 'Gear wheel pump flanges',
        description: 'In addition to a metric external thread in accordance with DIN 3901 in sizes L8 – L42 and S6 – S38, we also offer pump flanges with inch internal threads in two construction variants (round, square).',
        listItems: ["High-quality zinc-nickel coating", "Straight and 90° versions, each with pitch circles 30 to 55", "In steel or aluminium"],
        image: PumpFlange,
        buttonName: "READ MORE",
        buttonLink: "https://shop.hansa-flex.in/en_GB/flanges/pump-flanges/c/webcat_HF_FLA_0450"
    }

    const Data4 = {
        listItems: ["Four-hole flanges, high-pressure flanges, pump flanges", "Flange halves, full flanges screw-in and welded on flanges", "For applications up to 420 bar and available in all common sizes", "Black oiled, steel, stainless steel, metric, inch", "Electroplated", "All threads and connections to SAE, NPT, BSPP, UNF, JIC, CETOP"],
        image: PurchaseFlange,
    }

    const featuresData = [
        {
            title: "Robust materiale",
            description: "Suitable for high working pressures and highly dynamic loads.",
            image: imgMark
        },
        {
            title: "Pressure-resistant design",
            description: "The sealing surface has a flat face, reliably sealed using O-rings",
            image: imgMark
        },
        {
            title: "Releasable connection",
            description: "The two flange halves can be disassembled and reassembled without damaging the components",
            image: imgMark
        },
        {
            title: "Safe operation",
            description: "Leak-free connection of pipes and components in hydraulic systems",
            image: imgMark
        },
        // Add more objects for additional FeaturesCard data
    ];

    //HfTab data
    const imageTextData = [
        {
            title: 'Meets all standards and guidelines',
            description: "Flange connections from HANSA‑FLEX meet the requirements of relevant standards such as ISO 6162, SAE J 518 C, DIN 3901 / 3902, ISO/DIS 6164 and other international guidelines. All flanges are designed with international market requirements in mind. Consequently we make regular adjustments to the product portfolio to allow for specific further developments.",
            listItems: [
                "Manufactured in accordance with international standards and guidelines",
                "Portfolio development in accordance with market requirements",
                "Reliable delivery on time and, if required, in sequence"
            ],
            image: Compliment
        },
        {
            title: 'High product quality',
            description: "HANSA‑FLEX ISO 6164, CETOP and SAE flange connections are available in steel and in stainless steel designation number 1.4404, with a galvanised zinc coating. Gear wheel pump flanges are available in aluminium or steel with a high quality zinc-nickel coating. This provides 720 h corrosion resistance against red rust.",
            listItems: [
                "Materials: steel, stainless steel and aluminium",
                "Surface finish: galvanised zinc, zinc-nickel coating",
                "Welded or forged version depending on flange type"
            ],
            image: Quality
        },
        {
            title: 'For all fields of application',
            description: "Flanges are mainly used where pipe fittings are inappropriate because of their size, the required working pressure or lack of available space. We have a wide product portfolio for every field of application in fluid technology, such as industrial, mobile or marine hydraulics.",
            listItems: [
                "Plastic injection moulding and aluminium die casting plants",
                "Hydraulic presses, lifting and conveyor equipment",
                "Construction, agricultural and forestry machinery"
            ],
            image: Universal
        }
    ];

    const tabTitles = [
        { title: "Compliant with standards", eventKey: "Compliant with standards" },
        { title: "Quality", eventKey: "Quality" },
        { title: "Universal", eventKey: "Universal" },
    ];


    return (
        <div>
            <div>
                <CenterStage
                    imageSrc={Stage}
                    title={"FLANGES FROM HANSA‑FLEX"}
                    paragraph={"HANSA‑FLEX flange fittings are ideal for large diameter, high-pressure applications and restricted installation situations Flange connections are inherently resistant to strong impacts and vibrations. In addition they can be installed without much effort, even those with large nominal diameters, and in the tightest of installation spaces. They offer multiple advantages compared to screw-in connections of the same size."}
                />
            </div>

            <div>
                <SectionHeader
                    title={"FLANGES IN ACCORDANCE WITH SAE, ISO, CETOP REQUIREMENTS AND GEAR PUMP FLANGES"}
                    subtitle={"All HANSA‑FLEX flange connections are available in various types, such as adapter and fixed flange, block, welded and soldered connections."}
                />
                <ImageText
                    data={Data1} />
                <ImageText
                    data={Data2}
                    left={false} />
                <ImageText
                    data={Data3} />
            </div>

            <div>
                <SectionHeader
                    title={"FLANGES ARE A HIGHER CLASS OF PIPE CONNECTION"}
                    subtitle={"It is the connection technique most suitable for pipelines under highly dynamic loads, high working pressures and in tight installation spaces"}
                />
                <FeatureCardCarousel
                    featuresData={featuresData} />
            </div>

            <div>
                <SectionHeader
                    title={"SPECIAL FLANGES FROM THE HYDRAULIC EXPERTS"}
                    subtitle={"Our large selection of flange connections does not include the right product for your application? If so, then please speak to us directly! We can manufacture flange fittings to your individual requirements."}
                />
            </div>

            <div>
                <SectionHeader
                    title={"PURCHASE FLANGES ONLINE FROM THE HANSA‑FLEX ONLINE SHOP!"}
                    subtitle={"Discover the flange connection product portfolio from the system provider HANSA‑FLEX"}
                />
                <ImageText
                    data={Data4} />
            </div>

            <div>
                <SectionHeader
                    title={"YOU CANNOT GO WRONG WITH HANSA‑FLEX FLANGE CONNECTIONS"}
                />
                <HFTabs imageTextData={imageTextData} tabTitles={tabTitles} />
            </div>

            <div>
                <SectionHeader
                    title={"MORE HYDRAULIC COMPONENTS FROM HANSA‑FLEX"}
                />
                <div>
                    <Container className="custom-container">
                        <Row>
                            <Col sm={12} md={6} lg={4} ><ImageCard image={leakProof} learnMoreLink={"/pipe-fitting"}
                                title="Leak-proof positive connection with standardised pipe fittings"
                                desc={"HANSA‑FLEX pipe fittings rely on a tight positive fit with one another to create a safe connection, even when subjected to high working pressures, pressure peaks and..."} /></Col>

                            <Col sm={12} md={6} lg={4} ><ImageCard image={SpecialFittings} learnMoreLink={"/pipe-fitting"}
                                title="Special fittings and pipes as one-off, small batch or series manufactured items."
                                desc={"In the HANSA‑FLEX manufacturing centre for special pipes and fittings, components are made with computer-controlled precision for OEM or replacement part applications."} /></Col>

                            <Col sm={12} md={6} lg={4} ><ImageCard image={MadeToMeasure} learnMoreLink={"/pipebends"}
                                title="Made-to-measure bent pipes"
                                desc={"The HANSA‑FLEX pipe bending centre can produce bent pipes either individually or in batches. Its services also include computer-aided feasibility tests and 3D."} /></Col>
                        </Row>
                    </Container>
                </div>
            </div>


        </div>
    )
}

export default Flanges