import React, { useEffect } from 'react'
import CenterStage from '../../../Components/CenterStage/CenterStage'
import SectionHeader from '../../../Components/SectionHeader/SectionHeader'
import imgEDI_Centerspread from '../../../assets/Services/Digital Services/EDI and BMEcat/EDI_Centerspread.jpg'
import { useMediaQuery } from 'react-responsive'; // Import useMediaQuery hook
import ImageText from '../../../Components/ImageText/ImageText'
import imgEDI_Imagetext_01 from '../../../assets/Services/Digital Services/EDI and BMEcat/EDI_Imagetext_01.jpg'
import imgEDI_Imagetext_02 from '../../../assets/Services/Digital Services/EDI and BMEcat/EDI_Imagetext_02.jpg'
import imgEDI_Imagetext_03 from '../../../assets/Services/Digital Services/EDI and BMEcat/EDI_Imagetext_03.jpg'
import FeatureCardCarousel from '../../../Components/FeaturesCardCarousal/FeatureCardCarousal';
import imgMark from '../../../assets/mark.png'
import ImageCard from '../../../Components/ImageCard/ImageCard';
import { Container, Row, Col } from 'react-bootstrap';
import imgEDI_ImageCard_01 from '../../../assets/Services/Digital Services/EDI and BMEcat/EDI_ImageCard_01.jpg'
import imgEDI_ImageCard_02 from '../../../assets/Services/Digital Services/EDI and BMEcat/EDI_ImageCard_02.jpg'
import imgEDI_ImageCard_03 from '../../../assets/Services/Digital Services/EDI and BMEcat/EDI_ImageCard_03.jpg'

const EDIandBMEcat = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top on component mount
  }, []);

  const isLaptop = useMediaQuery({ minWidth: 1024 }); // Define the breakpoint for laptop screen size
  const isMobile = window.innerWidth <= 768;

  const EDI_Imagetext_01 = {
    image: imgEDI_Imagetext_01,
    title: 'Setting up an EDI interface to HANSA‑FLEX',
    listItems: ['As part of a long-term partnership, HANSA‑FLEX offers customers with requirements such as complete supplier integration and closely integrated processes or very high data transfer volumes a connection to our EDI system. The complexity of programming EDI interfaces means that the service is available only to selected customers who, because of high sales volumes with HANSA‑FLEX, expend a lot of resources on managing their goods procurement processes. When setting up the EDI, HANSA‑FLEX can use standard formats for data transfer such as EDIFACT, ODETTE, VDA, X12 and XML as well as special solutions to meet your specific requirements.'],
  }

  const EDI_Imagetext_02 = {
    image: imgEDI_Imagetext_02,
    title: 'Setting up of BMEcat by HANSA‑FLEX',
    listItems: ['BMEcat is a standardised catalogue data exchange format in accordance with the guidelines of the Bundesverband Materialwirtschaft, Einkauf und Logistik e. V. The catalogue data are extracted from the product data system at HANSA‑FLEX and integrated into your company’s system. HANSA‑FLEX installs this technology for customers who wish to have digital product data and individualised requirements in their product catalogue. Another benefit is that you can continue to use your familiar purchasing portal from where you procure all special components and HANSA‑FLEX delivers the hydraulic or pneumatic components you have ordered directly to you.'],
  }


  const EDI_Imagetext_03 = {
    image: imgEDI_Imagetext_03,
    title: 'Digital integration in three phases',
    listItems: ['The implementation of an EDI can take a few days or several months, depending on its complexity. The installation process always takes place in three phases. During the first phase, the necessary parameters such as data format and version of the formatting standards are determined. Then there is an initial integration test and a very careful comparison of the master and material data sets between your company and HANSA‑FLEX. In the test phase, we perform extensive tests, which involves interchange of test data. In some cases, corrections have to be made and the comparison of material data repeated. The integration is completed with the go-live phase including monitoring of the data flow.'],
  }

  const featuresData = [
    {
      title: "Flexible mapping",
      description: "If there is no suitable standard method, HANSA‑FLEX can configure your data format to your individual requirements.",
      image: imgMark
    },
    {
      title: "Years of experience with EDI",
      description: "The HANSA‑FLEX EDI specialists have decades of experience in this type of interface.",
      image: imgMark
    },
    {
      title: "Scheduling delivery plans for the year",
      description: "Using EDI, you can quickly and easily automate routine ordering processes in the medium or long term.",
      image: imgMark
    },
    {
      title: "Save resources using EDI",
      description: "Ordering via EDI significantly reduces the number of steps and paperwork costs in the ordering process.",
      image: imgMark
    }
    // Add more objects for additional FeaturesCard data
  ];








  return (
    <div>

      <CenterStage
        imageSrc={imgEDI_Centerspread}
        title={"YOUR DIGITAL INTERFACE TO HANSA‑FLEX VIA EDI AND BMECAT"}
        paragraph={"Electronic data interchange (EDI) enables rapid transfer of standardised business data between companies. The EDI interface between your and our merchandise management system enable you to manage all the steps in e-procurement even more efficiently. In addition to an EDI connection, HANSA‑FLEX can also offer you the possibility of integrating specific HANSA‑FLEX articles using BMEcat into your familiar purchasing portal.."}
      />

      <SectionHeader
        title={"ELECTRONIC DATA INTERCHANGE FOR MAJOR CUSTOMERS"}
        subtitle={"EDI makes data interchange for e-procurement between different companies easier and ensures quicker and fewer errors in your goods procurement processes."}
      />


      <div >
        <br />
        <ImageText data={EDI_Imagetext_01} left={false} /> <br /><br />
      </div>

      <div >
        <br />
        <ImageText data={EDI_Imagetext_02} /> <br /><br />
      </div>

      <div >
        <br />
        <ImageText data={EDI_Imagetext_03} left={false} /> <br /><br />
      </div>

      <SectionHeader
        title={"EDI IS YOUR DIRECT HOTLINE TO HANSA‑FLEX"}
        subtitle={"The well trained team in the HANSA‑FLEX EDI Service sets up your digital interface optimally for your requirements, so that you can profit from all the advantages of rapid electronic data interchange."}
      />

      <div>
        <FeatureCardCarousel featuresData={featuresData} />
      </div>

      <SectionHeader
        title={"FURTHER SERVICES FROM HANSA‑FLEX"}
        subtitle={""}
      />


      <Container className="custom-container">
        <Row>
          <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-right"}>
            <ImageCard image={imgEDI_ImageCard_01} learnMoreLink="/x-code-hose-identification-system" title="Hose management with X-CODE Manager" desc="X-CODE Manager makes hose management simply more manageable. For example, the package ensures you never lose track of your hose product inventory and inspection." />
          </Col>
          <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-left"}>
            <ImageCard image={imgEDI_ImageCard_02} learnMoreLink="https://www.hansa-flex.de/en/online-shop/" title="Order fluid technology requirements quickly and easily in the HANSA‑FLEX online shop" desc="Explore the HANSA‑FLEX online shop with over 80,000 articles relating to the field of fluid technology and place your order with just a few mouse clicks." />
          </Col>
          <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-right"}>
            <ImageCard image={imgEDI_ImageCard_03} learnMoreLink="/Scannersolutions" title="Scanner solutions for digital demand capture" desc="Order your components for fluid technology directly from the production floor or in your warehouse quickly and easily by scanner and HANSA‑FLEX’s Easy Order app." />
          </Col>

        </Row>
      </Container>







    </div>
  )
}

export default EDIandBMEcat