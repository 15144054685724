import React, { useEffect } from 'react'
import CenterStage from '../../../../Components/CenterStage/CenterStage'
import SectionHeader from '../../../../Components/SectionHeader/SectionHeader'
import ImageText from '../../../../Components/ImageText/ImageText'
import imgMark from '../../../../assets/mark.png';
import FeatureCardCarousel from '../../../../Components/FeaturesCardCarousal/FeatureCardCarousal';
import { Col, Container, Row } from 'react-bootstrap';
import ImageCard from '../../../../Components/ImageCard/ImageCard';

import imgStage from '../../../../assets/Products/DriveAndControlTechnology/ValveTechnology/Stage.jpg'
import imgPipeline from '../../../../assets/Products/DriveAndControlTechnology/ValveTechnology/Pipeline.jpg'
import imgCompactCartridge from '../../../../assets/Products/DriveAndControlTechnology/ValveTechnology/CompactCartridge.jpg'
import imgCetopValves from '../../../../assets/Products/DriveAndControlTechnology/ValveTechnology/CetopValves.jpg'
import imgSeriesProduces from '../../../../assets/Products/DriveAndControlTechnology/ValveTechnology/SeriesProduces.jpg'
import imgPrototypeDev from '../../../../assets/Products/DriveAndControlTechnology/ValveTechnology/PrototypeDev.jpg'
import imgCompact from '../../../../assets/Products/DriveAndControlTechnology/ValveTechnology/Compact.jpg'
import imgOptimised from '../../../../assets/Products/DriveAndControlTechnology/ValveTechnology/Optimised.jpg'
import imgSafety from '../../../../assets/Products/DriveAndControlTechnology/ValveTechnology/Safety.jpg'



const ValveTechnology = () => {
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
    }, []);

    const valveTech1 = {
        image: imgSeriesProduces,
        title: 'Series-produced hydraulic component groups',
        description: 'We work to suit your logistics and production processes On request, HANSA‑FLEX manufactures and installs complete component groups and can supply presorted components in kits. We offer you:',
        listItems: ["Design and series production from a single provider", "Production of individual items and hydraulic component groups", "Highest precision and Just-In-Sequence delivery"],
        buttonName: "READ MORE",
        buttonLink: "SeriesProduction"
    }

    const valveTech2 = {
        image: imgPrototypeDev,
        title: 'Prototype development',
        description: 'Consultation with our experts is a two-way process in which we develop the perfect solution for your application with you and support you from design to procurement. Your advantages from solutions developed with HANSA‑FLEX:',
        listItems: ["Development of customer-specific special parts on request", "Short-notice order call-offs", "Comprehensive customer care 24/7 from the mobile Rapid Hydraulic Service"],
        buttonName: "READ MORE",
        buttonLink: "/Prototypemanufacture"
    }

    // features card carousel data
    const featuresData = [
        {
            title: "Hydraulic components",
            description: "An extensive portfolio of everything from pumps and motors to valves and filters.",
            image: imgMark
        },
        {
            title: "Pumps",
            description: "We have vane, axial piston, radial piston and hand pumps in our warehouses.",
            image: imgMark
        },
        {
            title: "Measuring equipment and analysis systems",
            description: "Optimisation in hydraulic and pneumatic systems relies on measurement.",
            image: imgMark
        },
        // Add more objects for additional FeaturesCard data
    ];


    return (
        <div>
            <div>
                <CenterStage
                    imageSrc={imgStage}
                    title={"VALVE TECHNOLOGY FROM HANSA‑FLEX"}
                    paragraph={"Valves are at the heart of any hydraulic system. They switch, regulate and control the fluid flow. HANSA‑FLEX has an extensive portfolio of products for the field of valve technology. Examples include directional control valves with manual, electrical or pneumatic controls and shut-off, pressure, flow and proportional control valves for pressure ranges up to 350 bar. We carry a wide range of products in stock so you can generally expect short delivery times."}
                />
            </div>

            <div>
                <SectionHeader
                    title={"VALVES PERFECTLY MATCHED TO YOUR APPLICATION"}
                    subtitle={"Enjoy the benefits of HANSA‑FLEX valves with their high dynamics and short reaction times combined with excellent control capabilities for all applications and installation situations."}
                />
                <Container className="custom-container">
                    <Row>
                        <Col sm={12} md={6} lg={4} ><ImageCard image={imgPipeline} learnMoreLink={"/pipeline-installation-valve"}
                            title="Pipeline installation valves with simple basic functions"
                            desc={"These compact pipeline installation valves perform basic functions for controlling fluids and are installed between pipes directly in the hydraulic circuit."} /></Col>

                        <Col sm={12} md={6} lg={4} ><ImageCard image={imgCompactCartridge} learnMoreLink={"/cartridge-valve"}
                            title="Compact cartridge valves for tight installation spaces"
                            desc={"Being directly installed into the valve block, a cartridge valve is a space-saving means of implementing complex controls without additional valve housings."} /></Col>

                        <Col sm={12} md={6} lg={4} ><ImageCard image={imgCetopValves} learnMoreLink={"/cetop-valve"}
                            title="Implement a diverse range of valve functions with CETOP valves"
                            desc={"With their standardised connections and switching functions, CETOP valves can be combined in all sorts of ways to implement a wide range of functions."} /></Col>
                    </Row>
                </Container>
            </div>

            <div>
                <SectionHeader
                    title={"FROM CONCEPT AND PROTOTYPE DEVELOPMENT RIGHT UP TO SERIES PRODUCTION"}
                    subtitle={"Our fluid and hydraulics experts provide extensive technical advice on valve technology. They select suitable components and develop the optimum solution for you. Customer-specific valve blocks can be produced at short notice because HANSA‑FLEX has its own manufacturing workshops."}
                />
            </div>

            <div>
                <SectionHeader
                    title={"OUR WAREHOUSES GENERALLY STOCK AROUND 4,500 PRODUCTS IN PROVEN HANSA‑FLEX QUALITY."}
                    subtitle={"The wide range of hydraulic components from HANSA‑FLEX fulfil the highest quality standards and are readily available. In addition, we provide our customers with comprehensive advice so that they always receive the best solution in valve and control technology."}
                />
                <FeatureCardCarousel
                    featuresData={featuresData} />
            </div>

            <div>
                <SectionHeader
                    title={"HYDRAULICS SERVICES FROM THE FLUID EXPERTS AT HANSA‑FLEX"}
                    subtitle={"As a versatile and widely experienced system partner in fluid technology, we provide practical advice and support to our customers with mobile applications in the construction industry, machine and plant engineering and stationary hydraulic systems."}
                />
                <ImageText
                    data={valveTech1} />
                <ImageText
                    data={valveTech2}
                    left={false} />
            </div>

            <div>
                <SectionHeader
                    title={"THESE TOPICS MAY ALSO BE OF INTEREST TO YOU"}
                />
                <div>
                    <Container className="custom-container">
                        <Row>
                            <Col sm={12} md={6} lg={4} ><ImageCard image={imgCompact} learnMoreLink={"/Logisticssolutions"}
                                title="Components always readily available"
                                desc={"You can depend on HANSA‑FLEX! As a HANSA‑FLEX customer, you gain from on-schedule delivery and a wide range of logistics-related services."} /></Col>

                            <Col sm={12} md={6} lg={4} ><ImageCard image={imgOptimised} learnMoreLink={"/plant-project-planning-and-management"}
                                title="Optimised system design"
                                desc={"Our hydraulics specialists advise and assist you in planning your hydraulic system and ensure the whole system is designed for efficiency."} /></Col>

                            <Col sm={12} md={6} lg={4} ><ImageCard image={imgSafety} learnMoreLink={"/inspection"}
                                title="Safety and reliability confirmed by extensive testing"
                                desc={"Authorised personnel perform tests in-house or at your premises, including documentation of the tests and results."} /></Col>
                        </Row>
                    </Container>
                </div>
            </div>

        </div>
    )
}

export default ValveTechnology