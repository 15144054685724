import React, { useEffect } from 'react'
import CenterStage from '../../../Components/CenterStage/CenterStage'
import imgEngineeredby_Centerspread from '../../../assets/Services/Engineering/Engineered by Hansaflex/Engineeredby_Centerspread.jpg'
import SectionHeader from '../../../Components/SectionHeader/SectionHeader'
import imgEngineeredby_Imagetext_01 from '../../../assets/Services/Engineering/Engineered by Hansaflex/Engineeredby_Imagetext_01.jpg'
import imgEngineeredby_Imagetext_02 from '../../../assets/Services/Engineering/Engineered by Hansaflex/Engineeredby_Imagetext_02.jpg'
import FeatureCardCarousel from '../../../Components/FeaturesCardCarousal/FeatureCardCarousal'
import imgMark from '../../../assets/mark.png'

import ImageText from '../../../Components/ImageText/ImageText'

import { Container, Row, Col } from 'react-bootstrap';
import ImageCard from '../../../Components/ImageCard/ImageCard'
import imgEngineeredby_Imagecard_01 from '../../../assets/Services/Engineering/Engineered by Hansaflex/Engineeredby_Imagecard_01.jpg'
import imgEngineeredby_Imagecard_02 from '../../../assets/Services/Engineering/Engineered by Hansaflex/Engineeredby_Imagecard_02.jpg'
import imgEngineeredby_Imagecard_03 from '../../../assets/Services/Engineering/Engineered by Hansaflex/Engineeredby_Imagecard_03.jpg'
import { useMediaQuery } from 'react-responsive'; // Import useMediaQuery hook


const EngineeringbyHansaflex = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top on component mount
  }, []);

  const isLaptop = useMediaQuery({ minWidth: 1024 }); // Define the breakpoint for laptop screen size
  const isMobile = window.innerWidth <= 768;



  const Engineeredby_Imagetext_01 = {
    image: imgEngineeredby_Imagetext_01,
    title: 'Our development team provides all the services you require',
    description: ['HANSA‑FLEX coordinates a complex development process at the end of which you receive the product of proven quality. This process involves five steps:.'],
    listItems: ['Analysis and advice', 'Drawing and simulation', 'Tests on the prototype', 'Checks of initial sampling'],
  }





  const featuresData = [
    {
      title: "Cohesive system",
      description: "HANSA‑FLEX components are perfectly matched to one another for optimum performance.",
      image: imgMark
    },
    {
      title: "Consistent dimensions",
      description: "The series produced articles have a consistent appearance and dimensions, which means the installed system is as good as the designer intended",
      image: imgMark
    },
    {
      title: "Sustainable quality",
      description: "Our in-house manufacturing requirements and tests ensure a sustainable high level of quality.",
      image: imgMark
    },
    {
      title: "Standards and approvals",
      description: "We observe about 1,500 standards and have special industry approvals.",
      image: imgMark
    }
    // Add more objects for additional FeaturesCard data
  ];




  return (
    <div>

      <CenterStage
        imageSrc={imgEngineeredby_Centerspread}
        title={"COMPONENTS ENGINEERED BY HANSA‑FLEX"}
        paragraph={"HANSA‑FLEX develops high-quality components in-house to its own quality requirements. In this way, we can reliably provide high product quality independently of suppliers. We call this “engineered by HANSA‑FLEX”. Our technical specifications are consistent and the quality of our products exceeds that required by the legally applicable standards. Our highly qualified engineers produce products to the customer’s specific requirements in our own manufacturing facilities.."}
      />

      <div >
        <SectionHeader
          title={"IN-HOUSE ENGINEERING FROM ANALYSIS TO SERIES PRODUCTION"}
          subtitle={"The comprehensive know-how of our employees and the use of state-of-the-art equipment form the basis for the design of high-quality components engineered by HANSA‑FLEX."}
        />
      </div>


      <div >
        <br />
        <ImageText data={Engineeredby_Imagetext_01} left={false} />
      </div>






      <div >
        <SectionHeader
          title={"THE COMPONENTS DEVELOPED BY HANSA‑FLEX COME WITH THESE ADVANTAGES"}
          subtitle={"In-house developments from HANSA‑FLEX provide numerous benefits to our customers. Our own design and manufacturing capabilities mean we are independent of suppliers and are able to ensure high product quality and fulfil delivery promises."}
        />


      </div>


      <div>
        <FeatureCardCarousel featuresData={featuresData} />
      </div>

      {/* TODO: red carrousel */}
      {/* TODO: HFTabs */}

      <div >
        <SectionHeader
          title={"SOPHISTICATED QUALITY ASSURANCE"}
          subtitle={"HANSA‑FLEX subjects its products to a comprehensive quality assurance process. To do this we use high-precision equipment."}
        />


      </div>


      <div >
        <br />
        <ImageText data={Engineeredby_Imagetext_01} />
      </div>


      <div >
        <SectionHeader
          title={"THESE TOPICS MAY ALSO BE OF INTEREST TO YOU"}
          subtitle={""}
        />


      </div>


      <Container className="custom-container">
        <Row>
          <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-right"}>
            <ImageCard image={imgEngineeredby_Imagecard_01} learnMoreLink="/hydraulic-hoses" title="HANSA‑FLEX hydraulic hoses" desc="HANSA‑FLEX hydraulic hoses, some of which are developed in-house, fulfil the highest quality standards in the industry." />
          </Col>
          <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-left"}>
            <ImageCard image={imgEngineeredby_Imagecard_02} learnMoreLink="/pipe-fitting" title="High-quality pipe fittings" desc="Pipe fittings were one of the first in-house developments at HANSA‑FLEX. Even today, these hydraulic components are a safe way of connecting pipelines." />
          </Col>
          <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-right"}>
            <ImageCard image={imgEngineeredby_Imagecard_03} learnMoreLink="/sealing-tech" title="Reliable sealing technology" desc="A hydraulic system is only as good as its seals. Just one defective seal can lead to long downtimes. HANSA‑FLEX helps to prevent this." />
          </Col>


        </Row>
      </Container>












    </div>
  )
}

export default EngineeringbyHansaflex