import React from 'react';
import './TextCaption.css';

const TextCaption = ({ title, subtitle, paragraph, bulletPoints, numbered }) => {
  return (
    <div className="text-caption-header">
      {title && <h1 className="text-caption-title" style={{ color: '#28588F' }}>{title}</h1>}
      {subtitle && <p className="text-caption-subtitle">{subtitle}</p>}
      {paragraph && <p className="text-caption-paragraph">{paragraph}</p>}
      {bulletPoints && (
        <ul className={`bullet-point-list text-caption-bullets ${numbered ? 'numbered' : 'bullet'}`}>
          {bulletPoints.map((point, index) => (
            <li key={index}>{/* Using the Circle icon from React Icons */}
              {point}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default TextCaption;
