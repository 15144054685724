import React from 'react';
import CenterStage from '../Components/CenterStage/CenterStage';
import imgCareerStage from '../assets/Careers/career_stage_1.jpg';
import Promoter from '../Components/Promoter/Promoter';
import { Col, Row } from 'react-bootstrap';
import { MdEmail, MdLocationOn } from 'react-icons/md';
import imgWork from '../assets/Careers/work.png';

function Careers() {

    const isMobile = window.innerWidth <= 768;

    return (
        <div>
            <CenterStage title={"A PASSION FOR MACHINERY: YOUR CAREER AT HANSA‑FLEX"} imageSrc={imgCareerStage} paragraph={"At HANSA‑FLEX, we’re united by a passion for machinery and engineering. Are you always on the lookout for new challenges? Interested in engineering and have the innate drive to keep everything running? Then you’ve come to the right place. We’re proud of our open feedback culture, strong collegial cohesion and exciting tasks, making the work— whether in the office, warehouse, IT department or workshop— more than just a job. It’s the HANSA‑FLEX experience."} />
            {isMobile ?
                <>
                    <div style={{ backgroundColor: '#30557d', padding: '20px', color: 'white', paddingLeft: '10%' }}>
                        <Row>
                            <Col xs={12} md={6}>
                                <h2>Apply Now !</h2>
                                <p>
                                    <MdLocationOn /> HANSA-FLEX (IFP) INDIA PVT. LTD. <br />
                                    Plot No PAPV95, MIDC, Chakan Industrial Area, Village Vasuli, Taluka Khed, Pune, Maharashtra – 410501, India
                                </p>
                                <p>
                                    <MdEmail /> Email us at <a href="mailto:inp@hansa-flex.com" style={{ color: 'white' }}>inp@hansa-flex.com</a> now!
                                </p>
                            </Col>
                        </Row>
                    </div>
                </> : <Promoter title={"Contact us now !"} checklistItems={["Forge your path with passion and precision at HANSA-FLEX, where every challenge fuels innovation and every opportunity sparks growth"
                    , "Email us at inp@hansa-flex.com"]} imageUrl={imgWork} />}

        </div>
    );
}

export default Careers;