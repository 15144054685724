import React, { useEffect } from 'react';
import CenterStage from '../../Components/CenterStage/CenterStage';
import imgmissionStatement from '../../assets/MissionStatement/imgMissionStatement.jpg'
import ImageText from '../../Components/ImageText/ImageText';
import imgTogetherness from '../../assets/MissionStatement/imgToghetherness.jpg'
import SectionHeader from '../../Components/SectionHeader/SectionHeader';
import imgresponsibiliy from '../../assets/MissionStatement/imgResponsibility.jpg'
import imgReliability from '../../assets/MissionStatement/imgReliability.jpg'
import imgEnviromentalAwarness from '../../assets/MissionStatement/imgEnvironmentalawareness.png'
import imgRD from '../../assets/MissionStatement/imgR&D.png'
import imgFlexibility from '../../assets/MissionStatement/imgFlexibility.png'

function GroupMissionStatement() {

    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
    }, []);

    const Togetherness = {
        image: imgTogetherness,
        title: 'Sealed Precision: HANSA-FLEX Threaded Nipples',
        listItems: ['We treat each other with appreciation and respect.', 'We act in a cooperative manner and support each other', 'We communicate with one another openly and honestly.', 'We make sure that we and others are always well-informed.', 'We are part of a larger whole'],
    }

    const Responsibiliy = {
        image: imgresponsibiliy,
        title: 'Responsibility',
        listItems: ['We deliver quality and expect the same from our partners', 'We encourage our staff to work on their own initiative.', 'We question things critically and respect the opinions of others..', 'We are aware of our responsibilities towards colleagues and partners and implement these responsibilities.'],
    }


    const Reliability = {
        image: imgReliability,
        title: 'Reliability',
        listItems: ['We deliver quality and expect the same from our partners', 'We encourage our staff to work on their own initiative.', 'We question things critically and respect the opinions of others..', 'We are aware of our responsibilities towards colleagues and partners and implement these responsibilities.'],
    }


    const EnviromentalAwarness = {
        image: imgEnviromentalAwarness,
        title: 'EnviromentalAwarness',
        listItems: ['We conserve natural resources.', 'We promote energy efficiency in hydraulics with regard to our customers, products and services.', 'We ensure that our processes are sustainable.'],
    }

    const RD = {
        image: imgRD,
        title: 'R&D',
        listItems: ['We develop innovative products and services.', 'We continuously expand our professional expertise products and services.', 'We encourage and challenge our workforce.', 'We support our partners with consultancy and training.', 'We all work together to ensure the success of HANSA‑FLEX.'],
    }

    const Flexibility = {
        image: imgFlexibility,
        title: 'Flexibility',
        listItems: ['We respond to the individual requirements of our partners', 'We offer our colleagues flexible opportunities to work throughout the group', 'We identify changes at an early stage and respond immediately']
    }










    return (
        <div>
            <div>
                <CenterStage
                    imageSrc={imgmissionStatement}
                    title={"A FOCUS ON TOGETHERNESS"}
                    paragraph={"The HANSA‑FLEX Mission Statement forms the orientation framework for the daily actions of all employees worldwide. The underlying values and principles were developed in joint workshops by employees, managers and the owner families. Together with our HANSA‑FLEX Code of Conduct, we have a stable framework of values forming the basis of our corporate culture."}
                />
            </div>

            <div className='div-gray'>
                <SectionHeader
                    title={"OUR DRIVE IS IN OUR DNA"}
                    subtitle={"The values and principles of our Mission Statement exemplify HANSA‑FLEX’s commitment to its customers, guiding us in our daily operations both internal and external."}
                />

                <div >
                    <br />
                    <ImageText data={Togetherness} left={false} /> <br /><br />
                </div>

                <div >
                    <br />
                    <ImageText data={Responsibiliy} /> <br /><br />
                </div>

                <div >
                    <br />
                    <ImageText data={Reliability} left={false} /> <br /><br />
                </div>

                <div >
                    <br />
                    <ImageText data={RD} /> <br /><br />
                </div>

                <div >
                    <br />
                    <ImageText data={Flexibility} left={false} /> <br /><br />
                </div>



            </div>




        </div>
    );
}

export default GroupMissionStatement;