import React, { useEffect } from 'react';
import SectionHeader from '../Components/SectionHeader/SectionHeader';
import TextCaption from '../Components/TextCaption/TextCaption';

function PrivayPolicyPage() {

    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
    }, []);
    return (
        <div>
            <SectionHeader title={"Privacy Policy for HANSA-FLEX India "} paragraph={"At HANSA-FLEX India, we are committed to protecting your privacy and ensuring the security of your personal information. This privacy policy describes how we collect, use, and protect your personal information when you visit our website or use our services. By using our website and services, you consent to the collection, use, and disclosure of your personal information in accordance with this policy. "} />
            <TextCaption title={"Recitals: "} bulletPoints={["We recognize the importance of protecting the privacy and confidentiality of personal information that we collect from our customers and website users. "
                , "We are committed to complying with all applicable laws and regulations relating to data protection, including the Indian Information Technology Act, 2000 and the Indian Personal Data Protection Bill, 2019 (once it comes into effect). "
                , "This privacy policy sets out our practices and procedures for collecting, using, storing, and disclosing personal information. "]} />
            <TextCaption title={"Party clause: "} bulletPoints={["This privacy policy is a binding agreement between you (the user of our website and services) and HANSA-FLEX India. "
                , "By accessing or using our website or services, you agree to the terms of this privacy policy. "]} />

            <SectionHeader title={"Definitions"} titleSize={"2.5rem"} />
            <TextCaption
                title={"In this policy: "}
                bulletPoints={[
                    "Personal information means any information that can be used to identify an individual, including but not limited to name, address, email address, phone number, and payment information.",
                    "We, us, and our refer to HANSA-FLEX India and its affiliates, subsidiaries, and partners.",
                    "You and your refer to the user of our website and services."
                ]} />

            <TextCaption
                title={"Purpose:"}
                paragraph={"The purpose of the HANSA-FLEX Privacy Policy is to inform our customers, website visitors, and users about the collection, use, and protection of their personal information. We are committed to protecting the privacy and security of our users' personal information, and this policy outlines the measures we take to ensure that their information is processed lawfully, fairly, and transparently. "}
            />

            <TextCaption
                paragraph={"The policy also explains the choices users have with respect to the collection, use, and sharing of their personal information. By providing this information, we aim to empower our users to make informed decisions about how their personal data is used and to ensure that their rights are respected. "} />

            <TextCaption paragraph={"Additionally, the policy provides transparency about the practices and procedures we have in place to protect our users' personal information from unauthorized access, disclosure, and misuse. By maintaining these high standards of data protection, we strive to maintain the trust and confidence of our customers, website visitors, and users. "} />
            <TextCaption title={"Scope of the HANSA-FLEX privacy policy "} paragraph={"The scope of the HANSA-FLEX India Privacy Policy is to inform our website visitors and customers about the collection, use, sharing, and protection of their personal information. The policy applies to all personal data collected through our website, services, and other business operations in India. Our policy also covers the use of cookies and tracking technologies, as well as the disclosure of personal information to third-party service providers, law enforcement, government agencies, and other legal requirements. We are committed to protecting the privacy and security of our users' personal information and complying with all applicable data protection laws and regulations in India and around the world. "} />
            <TextCaption title={"Data Collection "} paragraph={"We may collect personal information from you when you visit our website, register on our site, place an order, subscribe to our newsletter, fill out a form, or use our services. The information we may collect includes your name, email address, phone number, company name, address, and other contact information. "} />
            <TextCaption paragraph={"Categories of Personal Information Collected "} bulletPoints={["Contact information, such as name, email address, phone number, and company name "
                , "Payment information, such as credit card number, billing address, and payment history"
                , "Usage data, such as IP address, browser type, device type, and website activity "
                , "Other information you provide to us voluntarily, such as feedback, comments, or testimonials"]} />
            <TextCaption title={"Data Usage "} paragraph={"We may use your personal information to improve our website and services, communicate with you, process orders, provide customer support, and send you promotional emails. We may also use your data to comply with legal requirements, prevent fraud, and protect our rights and property. "} />
            <TextCaption title={"Payments "} paragraph={"We may collect payment information from you when you place an order on our website. We use third-party payment processors to process your payments securely. We do not store your payment information on our servers. "} />
            <TextCaption title={"Data Sharing "} paragraph={"We may share your personal information with our affiliates, subsidiaries, and partners for business purposes. We may also share your data with third-party service providers who help us operate our website and services, such as payment processors, shipping companies, and marketing agencies. We will only share your data with these third parties to the extent necessary to fulfill their services."} />
            <TextCaption paragraph={"Disclosure of Personal Data We may disclose your personal information to third parties in the following circumstances: "}
                bulletPoints={["To comply with legal obligations or a court order. "
                    , "In response to lawful requests by public authorities, including to meet national security or law enforcement requirements. "
                    , "To protect and defend the rights or property of HANSA-FLEX India, including to investigate and prevent fraud or security breaches. "
                    , "In connection with a business transaction, such as a merger, acquisition, or sale of assets, where your personal information may be transferred to the new owner or partner. "
                    , "To third-party service providers who assist us in delivering our website and services, such as payment processors, shipping companies, and marketing agencies. "
                ]} />

            <TextCaption title={"Data Storage: "}
                bulletPoints={["We will store your personal information on secure servers located within India or in other countries where our affiliates, subsidiaries, or service providers operate. "
                    , "We may transfer your personal information to other countries, and we will take appropriate measures to ensure that your data is protected in accordance with this policy. "
                    , "We will retain your personal information for as long as necessary to fulfill the purposes for which we collected it, including for legal and accounting purposes. "]}
            />

            <TextCaption title={"Data Security: "}

                bulletPoints={["We take appropriate technical and organizational measures to protect your personal information from unauthorized access, disclosure, alteration, or destruction. "
                    , "We regularly review and update our security measures to ensure that your data is protected in accordance with applicable laws and regulations. "
                    , "We use SSL encryption to protect your personal information during transmission over the internet. "
                    , "We restrict access to your personal information to employees who need to know that information to provide you with our services. "
                    , "We will promptly notify you in the event of a security breach involving your personal information. "
                    , "Despite our efforts to protect your personal information, we cannot guarantee the security of your data transmitted to our website. Any transmission of personal information is at your own risk. "

                ]}
            />

            <TextCaption
                title={"Data Retention "}
                paragraph={"We will retain your personal information for as long as necessary to fulfill the purposes for which we collected it, including for legal and accounting purposes. We may also retain your data for a longer period if required by law or if necessary to resolve disputes or enforce our agreements. "}

            />

            <TextCaption
                title={"Cookies and Tracking "} />
            <TextCaption paragraph={"We use cookies and other tracking technologies to improve your browsing experience, analyze website traffic, and personalize content and advertisements. You can choose to accept or reject cookies in your browser settings, but please note that some features of our website may not function properly if you disable cookies. "} />
            <TextCaption paragraph={"Data Collection from Newsletter Subscription: When a user subscribes to our newsletter, we collect certain personal information, such as their name and email address, in order to send them our newsletter and other marketing communications. This data is collected with the user's consent and is processed in accordance with applicable data protection laws. "} />
            <TextCaption paragraph={"Google Analytics: Our website uses Google Analytics, a web analytics service provided by Google, Inc. Google Analytics uses cookies to help us analyze how users use our website. The information generated by the cookie about your use of the website (including your IP address) will be transmitted to and stored by Google on servers in the United States. Google will use this information for the purpose of evaluating your use of the website, compiling reports on website activity for website operators and providing other services relating to website activity and internet usage. Google may also transfer this information to third parties where required to do so by law, or where such third parties process the information on Google's behalf. By using our website, you consent to the processing of data about you by Google in the manner and for the purposes set out above. "} />
            <TextCaption paragraph={"Google ReCaptcha: We use Google ReCaptcha on our website to help protect against spam and abuse. Google collects certain information about the user's browser and device, including cookies and other unique identifiers, in order to provide this service. This data is processed in accordance with Google's privacy policy. "} />
            <TextCaption paragraph={"Google Maps: Our website may use Google Maps to provide location information. Google collects certain information about the user's browser and device, including cookies and other unique identifiers, in order to provide this service. This data is processed in accordance with Google's privacy policy. "} />
            <TextCaption paragraph={"Facebook: Our website may use Facebook pixels and other Facebook tracking technologies to track user interactions with our website and to show them personalized advertisements on Facebook. Facebook collects certain information about the user's browser and device, including cookies and other unique identifiers, in order to provide this service. This data is processed in accordance with Facebook's privacy policy. "} />
            <TextCaption paragraph={"YouTube: Our website may embed videos from YouTube. YouTube collects certain information about the user's browser and device, including cookies and other unique identifiers, in order to provide this service. This data is processed in accordance with YouTube's privacy policy. "} />
            <TextCaption paragraph={"All of the above-mentioned third-party services are subject to their own terms and privacy policies, and users are advised to review these policies before using our website or providing any personal information. We are not responsible for the data collection, use, or disclosure practices of these third-party services. "} />

            <TextCaption title={"Social Media Accounts "}
                paragraph={"We may collect personal information from you if you follow or interact with our social media accounts. We may use your data to communicate with you, respond to your inquiries, and provide customer support. "} />

            <TextCaption title={"Data Transfer "} paragraph={"If you are located outside of India, your personal information may be transferred to and processed in other countries where our affiliates, subsidiaries, or service providers operate. By using our website and services, you consent to the transfer of your data to these countries. "} />
            <TextCaption title={"Children's Privacy "} paragraph={"Our website and services are not intended for children under the age of 16. We do not knowingly collect personal information from children under the age of 16.  "} />
            <TextCaption title={"Hyperlink Policy: "} />
            <TextCaption paragraph={"Our website may contain hyperlinks to other websites or portals operated by third parties. These links are provided for your reference and convenience only. We have no control over the contents of those websites or portals and accept no responsibility for them or for any loss or damage that may arise from your use of them. "} />
            <TextCaption paragraph={"When you click on a hyperlink on our website, you may leave our website and be directed to another website or portal. We do not endorse or guarantee the accuracy, completeness, or usefulness of any content, information, or other materials found on those websites or portals. We also do not endorse or guarantee any products or services advertised on those websites or portals. "} />
            <TextCaption paragraph={"Please be aware that these other websites or portals may have different privacy policies and terms of use that are beyond our control. We recommend that you review the terms and policies of those websites or portals before using them. "} />

            <TextCaption title={"Links to our Website: "} paragraph={"You may link to our website without our prior written permission, as long as the link is done in a way that is fair and legal and does not damage our reputation or take advantage of it. You must not establish a link in such a way as to suggest any form of association, approval, or endorsement on our part where none exists. "} />
            <TextCaption paragraph={"If you would like to link to our website in a way that is not described above, please contact us at [contact email] and provide us with details of the proposed link and the context in which it would appear. "} />
            <TextCaption title={"Applicable Law: "} paragraph={"This privacy policy shall be governed by and construed in accordance with the laws of India. Any dispute arising under or related to this policy shall be resolved exclusively by the courts of India."} />

            <TextCaption paragraph={"We reserve the right to modify or update this privacy policy at any time. Any changes to this policy will be posted on this website, and the revised policy will take effect as soon as it is posted. Your continued use of our website after any changes to this policy will constitute your acceptance of those changes. "} />
            <TextCaption title={"Dispute Resolution: "}
                paragraph={"In case of any dispute, controversy or claim arising out of or in connection with this Privacy Policy or any breach thereof, the parties shall first attempt to resolve the dispute through good faith negotiations. If the parties are unable to resolve the dispute through negotiations within a reasonable time, either party may refer the dispute to mediation in accordance with the Indian Mediation and Conciliation Rules, 2016. If the dispute is not resolved through mediation, it shall be finally settled by arbitration in accordance with the Indian Arbitration and Conciliation Act, 1996. The arbitration shall be conducted in English and shall be held in India. The decision of the arbitrator shall be final and binding upon the parties. "} />
            <TextCaption title={"Arbitration "}
                paragraph={"Any dispute, controversy, or claim arising out of or relating to this Privacy Policy or the breach, termination, enforcement, interpretation, or validity thereof, including the determination of the scope or applicability of this agreement to arbitrate, shall be determined by arbitration in accordance with the Indian Arbitration and Conciliation Act, 1996. The arbitration shall be conducted in English, by a single arbitrator appointed by mutual agreement of the parties, or failing such agreement, by the Chairman of the Indian Council of Arbitration. The seat of arbitration shall be in Pune, and the arbitration proceedings shall be conducted in accordance with the Indian Arbitration and Conciliation Act, 1996, and any rules promulgated thereunder. The award rendered by the arbitrator shall be binding upon the parties and may be entered as a judgment in any court having jurisdiction thereof. Each party shall bear its own costs in the arbitration. "} />
            <TextCaption paragraph={"This arbitration clause requires any disputes or claims related to the privacy policy to be resolved through arbitration in accordance with Indian law and the Indian Arbitration and Conciliation Act. It also specifies the language of arbitration, the appointment of a single arbitrator, and the seat of arbitration. Finally, it notes that the arbitrator's decision will be binding and enforceable as a judgment in a court of law, and that each party will be responsible for its own arbitration costs."} />
            <TextCaption title={"Your Rights as a Data Subject: "}
            />
            <TextCaption paragraph={"HANSA-FLEX would like to take this opportunity to inform you about your rights as a data subject under applicable data protection laws. These rights include: "
            } bulletPoints={["Right of Access: You have the right to request access to your personal data processed by HANSA-FLEX. Upon request, we will provide you with a copy of your personal data in a structured, commonly used, and machine-readable format. "
                , "Right to Rectification: You have the right to request the rectification of inaccurate or incomplete personal data concerning you that is processed by HANSA-FLEX. "
                , "Right to Erasure: You have the right to request the erasure of your personal data processed by HANSA-FLEX. However, please note that this right is not absolute and may be limited by applicable law. "
                , "Right to Restriction of Processing: You have the right to request the restriction of processing of your personal data processed by HANSA-FLEX under certain circumstances, such as if you contest the accuracy of the personal data. "
                , "Right to Object: You have the right to object to the processing of your personal data by HANSA-FLEX, including for direct marketing purposes. "
                , "Right to Data Portability: You have the right to receive your personal data processed by HANSA-FLEX in a structured, commonly used, and machine-readable format and to transmit that data to another controller without hindrance from HANSA-FLEX. "]} />
            <br /><br /><br />
            <TextCaption paragraph={"If you wish to exercise any of these rights, please contact us at [insert contact details] and we will respond to your request within the time period required by applicable law. Please note that we may request additional information from you in order to verify your identity before processing your request. "} />
            <TextCaption paragraph={"Please be aware that these rights are not absolute and may be subject to limitations and exemptions under applicable law. In addition, we may retain certain personal data as required or permitted by law or as necessary for our legitimate business purposes. "} />

            <TextCaption title={"Grievance Redressal "} paragraph={"We take our users' privacy concerns seriously. In the event that you have any grievance or complaint regarding the processing or handling of your personal data, please contact our Grievance Officer at the contact details provided below: "} />

            <TextCaption paragraph={"Grievance Officer:"} />
            <TextCaption paragraph={"Name: Address:"} />
            <TextCaption paragraph={"Email: Phone:"} />
            <TextCaption paragraph={"We will endeavor to respond to your grievance within a reasonable time and take appropriate action as necessary. "} />\
            <TextCaption paragraph={"If you are not satisfied with our response to your complaint or if you believe that your rights have been violated under applicable data protection laws, you may file a complaint with the relevant data protection authority in your jurisdiction. "} />
            <TextCaption paragraph={"We reserve the right to modify or amend this Privacy Policy at any time. Any changes to this Privacy Policy will be posted on our website and will become effective immediately upon posting. By continuing to use our website and services, you agree to be bound by the updated Privacy Policy. "} />

            <TextCaption title={"Indian Section: "}
                paragraph={"We acknowledge and respect your privacy rights in India. We comply with the applicable data protection laws in India, including the Information Technology (Reasonable Security Practices and Procedures and Sensitive Personal Data or Information) Rules, 2011. "} />
            <TextCaption paragraph={"As per Indian law, we are required to obtain your consent before collecting any sensitive personal information from you. We will obtain your explicit consent prior to collecting any sensitive personal information such as financial information, health information, or biometric information."} />

            <TextCaption paragraph={"If you are located in India, you have the right to request access to your personal information, correct any inaccuracies, and delete your personal information. You can exercise these rights by contacting us using the information provided below. "} />

            <TextCaption paragraph={"We may update this privacy policy from time to time. Any changes we make to our privacy policy will be posted on this page, and we will notify you of any material changes via email or through our website. Please check this page periodically to review our current privacy policy. "} />

            <TextCaption paragraph={"If you have any questions or concerns about our privacy policy or our practices regarding your personal information, please contact us at info@hansa-flex.com. "} />

        </div>
    );
}

export default PrivayPolicyPage;