import React, { useEffect } from 'react';
import HFTab from '../../../Components/HfTabs/HfTabs';
import CenterStage from '../../../Components/CenterStage/CenterStage';
import SectionHeader from '../../../Components/SectionHeader/SectionHeader';
import { Col, Container, Row } from 'react-bootstrap';
import ImageCard from '../../../Components/ImageCard/ImageCard';
import ImageText from '../../../Components/ImageText/ImageText';
import Promoter from '../../../Components/Promoter/Promoter';
import FeatureCardCarousel from '../../../Components/FeaturesCardCarousal/FeatureCardCarousal';
import imgMark from '../../../assets/mark.png';

import Stage from '../../../assets/Services/AssemblyAndInstallation/AssemblyAndInstallation/Stage.jpg'
import Intelligent from '../../../assets/Services/AssemblyAndInstallation/AssemblyAndInstallation/Intelligent.jpg'
import ManagePlant from '../../../assets/Services/AssemblyAndInstallation/AssemblyAndInstallation/ManagePlant.jpg'
import InstallationOfPipe from '../../../assets/Services/AssemblyAndInstallation/AssemblyAndInstallation/InstallationOfPipe.jpg'
import OneOff from '../../../assets/Services/AssemblyAndInstallation/AssemblyAndInstallation/OneOff.jpg'
import PerfectHose from '../../../assets/Services/AssemblyAndInstallation/AssemblyAndInstallation/PerfectHose.jpg'
import EfficientHose from '../../../assets/Services/AssemblyAndInstallation/AssemblyAndInstallation/EfficientHose.jpg'
import Connected from '../../../assets/Services/AssemblyAndInstallation/AssemblyAndInstallation/Connected.jpg'
import Logistics from '../../../assets/Services/AssemblyAndInstallation/AssemblyAndInstallation/Logistics.jpg'
import Innovation from '../../../assets/Services/AssemblyAndInstallation/AssemblyAndInstallation/Innovation.jpg'

const AssemblyAndInstallation = () => {
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
    }, []);

    const featuresData = [
        {
            title: "Industrial Service",
            description: "Our service technicians support you in all your servicing, maintenance and assembly work.",
            image: imgMark
        },
        {
            title: "Fluid Service",
            description: "HANSA‑FLEX fluid experts use their know-how to handle the care of your hydraulic oil.",
            image: imgMark
        },
        {
            title: "Emergency Service",
            description: "Our mobile rapid call-out team is always ready to help you, 24/7 and 365 days a year.",
            image: imgMark
        }
    ];


    return (
        <div>
            <div>
                <CenterStage
                    imageSrc={Stage}
                    title={"HYDRAULIC SERVICES FOR INDUSTRY"}
                    paragraph={"Our excellently trained service technicians carry out assembly and installation work on hydraulic systems. Irrespective of whether the work involves a stationary or a mobile unit, system design or practical implementation: You can benefit from the comprehensive range of products and services from our Industrial Service [Verlinkung LP “Industrieservice”] for all tasks to do with your hydraulic system."}
                />
            </div>

            <div>
                <SectionHeader
                    title={"THE COMPLETE SERVICE FOR YOUR HYDRAULIC SYSTEMS"}
                    subtitle={"The experienced hydraulics specialists at HANSA‑FLEX Industrial Service are your one-stop partners and always ready to help and assist you with anything from planning and design, operation and maintenance right up to renovation."}
                />
                <div>
                    <Container className="custom-container">
                        <Row>
                            <Col sm={12} md={6} lg={4}><ImageCard image={Intelligent} learnMoreLink={"/modernisation-and-optimisation"}
                                title="Intelligent renovation"
                                desc={"Renovation does not always require the purchase of a whole new system or plant. Often, intelligent renovation and improvement measures can result in higher..."} /></Col>

                            <Col sm={12} md={6} lg={4}><ImageCard image={ManagePlant} learnMoreLink={"/plant-project-planning-and-management"}
                                title="Manage plant project planning with know-how"
                                desc={"To ensure that all hydraulic components work together efficiently in complex plants or systems requires a great deal of know-how and careful planning. Benefit from the..."} /></Col>

                            <Col sm={12} md={6} lg={4}><ImageCard image={InstallationOfPipe} learnMoreLink={"/installation-of-pipe-and-hoseline-systems"}
                                title="Installation of pipe and hose line systems"
                                desc={"Our service technicians and installation teams install all the pipes and hoses on machines, systems or mobile hydraulic units. From prototypes to major projects."} /></Col>
                        </Row>
                    </Container>
                </div>
            </div>

            <div>
                <SectionHeader
                    title={"FURTHER HANSA‑FLEX HYDRAULICS SERVICES"}
                    subtitle={"Our mobile service vehicles and technicians are always available to assist you, for example, by providing industrial hydraulics services, oil analyses, oil care or a 24-hour emergency service."}
                />
                <FeatureCardCarousel
                    featuresData={featuresData} />
            </div>

            <div>
                <SectionHeader
                    title={"HOSE LINES AND PIPELINES FROM HANSA‑FLEX"}
                    subtitle={"As a system provider, HANSA‑FLEX can supply hydraulic hose lines for everything from mobile applications in construction, agriculture and forestry right up to stationary hydraulic systems in the chemical, pharmaceutical and food industries."}
                />
                <div>
                    <Container className="custom-container">
                        <Row>
                            <Col sm={12} md={6} lg={4}><ImageCard image={OneOff} learnMoreLink={"/pipelines"}
                                title="One-off or series manufactured pipelines"
                                desc={"Bent precision pipes from HANSA‑FLEX are available in various materials, including steel, stainless steel, copper or aluminium and with different surface treatments."} /></Col>

                            <Col sm={12} md={6} lg={4}><ImageCard image={PerfectHose} learnMoreLink={"/hoses-and-hose-lines"}
                                title="The perfect hose line for every application"
                                desc={"The wide range of HANSA‑FLEX hydraulic hose types, including braided and plastic hoses, has every field of use covered."} /></Col>

                            <Col sm={12} md={6} lg={4}><ImageCard image={EfficientHose} learnMoreLink={"/hose-managemen"}
                                title="Efficient hose management"
                                desc={"Hose line inspection, on-schedule replacement and clear documentation: Optimise your hose management with X-CODE and the My.HANSA‑FLEX platform."} /></Col>
                        </Row>
                    </Container>
                </div>
            </div>

            <div>
                <SectionHeader
                    title={"RELIABLE INSPECTIONS"}
                    subtitle={"Our service technicians have officially authorized person status for performing DGUV, industrial hose, pressure accumulator, lifting and loading platform inspections."}
                />
            </div>

            <div>
                <SectionHeader
                    title={"THESE TOPICS MAY ALSO BE OF INTEREST TO YOU"}
                />
                <div>
                    <Container className="custom-container">
                        <Row>
                            <Col sm={12} md={6} lg={4}><ImageCard image={Connected} learnMoreLink={"/hydraulic-connection-technology"}
                                title="Always well connected "
                                desc={"Hydraulic connecting elements, such as adapters, fittings and threaded connectors, are an essential means of joining components in hydraulic systems."} /></Col>

                            <Col sm={12} md={6} lg={4}><ImageCard image={Logistics} learnMoreLink={"/Logisticssolutions"}
                                title="Logistical support"
                                desc={"We offer logistics services such as warehouse management, kanban and kitting. They are designed to help you optimize your processes."} /></Col>

                            <Col sm={12} md={6} lg={4}><ImageCard image={Innovation} learnMoreLink={"/Engineering"}
                                title="Innovation and manufacture"
                                desc={"Whether you require development, design or prototype manufacturing: As a system partner, HANSA‑FLEX provides you with a full range of engineering services, all from a..."} /></Col>
                        </Row>
                    </Container>
                </div>
            </div>

        </div>
    )
}

export default AssemblyAndInstallation