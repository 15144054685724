import React, { useEffect } from 'react'
import CenterStage from '../../../../Components/CenterStage/CenterStage'
import SectionHeader from '../../../../Components/SectionHeader/SectionHeader'
import ImageText from '../../../../Components/ImageText/ImageText'
import imgMark from '../../../../assets/mark.png';
import FeatureCardCarousel from '../../../../Components/FeaturesCardCarousal/FeatureCardCarousal';
import HFTabs from '../../../../Components/HfTabs/HfTabs';
import ImageCard from '../../../../Components/ImageCard/ImageCard';
import { Col, Container, Row } from 'react-bootstrap';

import imgStage from '../../../../assets/Products/DriveAndControlTechnology/CartridgeValve/Stage.jpg'
import imgComplexvalve from '../../../../assets/Products/DriveAndControlTechnology/CartridgeValve/Complexvalve.jpg'
import imgHighlyEfficient from '../../../../assets/Products/DriveAndControlTechnology/CartridgeValve/HighlyEfficient.jpg'
import imgEfficientAndRobust from '../../../../assets/Products/DriveAndControlTechnology/CartridgeValve/EfficientAndRobust.jpg'
import imgHousing from '../../../../assets/Products/DriveAndControlTechnology/CartridgeValve/Housing.jpg'
import imgValveBlock from '../../../../assets/Products/DriveAndControlTechnology/CartridgeValve/ValveBlock.jpg'
import imgScrewIn from '../../../../assets/Products/DriveAndControlTechnology/CartridgeValve/ScrewIn.jpg'
import imgReplaceSolenoid from '../../../../assets/Products/DriveAndControlTechnology/CartridgeValve/ReplaceSolenoid.jpg'
import imgIntermediatePlate from '../../../../assets/Products/DriveAndControlTechnology/CartridgeValve/IntermediatePlate.jpg'
import imgSeals from '../../../../assets/Products/DriveAndControlTechnology/CartridgeValve/Seals.jpg'
import imgReliable from '../../../../assets/Products/DriveAndControlTechnology/CartridgeValve/Reliable.jpg'
import imgIndividualised from '../../../../assets/Products/DriveAndControlTechnology/CartridgeValve/Individualised.jpg'
import imgValveTech from '../../../../assets/Products/DriveAndControlTechnology/CartridgeValve/ValveTech.jpg'


const CartridgeValves = () => {
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
    }, []);

    const CartridgeValve1 = {
        image: imgComplexvalve,
        title: 'Complex valve blocks in a compact format',
        description: 'Screw-in cartridge valves perform the same control functions as pipeline installation valves. In the case of cartridge valves, however, the valve and the housing are two separate components. This allows the housings to be combined with various functional valves as required. Complex controls can be installed in the smallest spaces by screwing several different functional valves into one valve block. The ISO standardisation of functional valves and cartridge bores allow flexibility in the way they are configured.',
    }

    const CartridgeValve2 = {
        image: imgHighlyEfficient,
        title: 'Highly efficient, diverse in application',
        description: 'High-quality housings and highly precise profile bores for the screw-in cartridge valves minimise pressure fluctuations in machines and systems and increase their efficiency. Their compactness and low weight mean cartridge valves can increase the efficiency of systems, especially in mobile hydraulics. The materials used to construct the components can be chosen to suit the requirements and conditions in their areas of application.',
    }

    const CartridgeValve3 = {
        image: imgEfficientAndRobust,
        title: 'Efficient and robust screw-in cartridge valves',
        description: 'Screw-in cartridge valves are used for flow and pressure regulation among other things. They are known for their high switching speed, very low leakage, good control of high flows and ability to withstand the loads resulting from high pressures.',
        listItems: ["Flows up to 230 litres per minute", "Permitted working pressure up to 350 bar"],
    }

    const CartridgeValve4 = {
        image: imgHousing,
        title: 'Housings for every application',
        description: 'You can obtain various types of housings for cartridge valves from HANSA‑FLEX. Manufactured from cast-iron, stainless steel or aluminium with the appropriate surface coating, they can be used in almost all environments. HANSA‑FLEX also offers valves specially designed for potentially explosive atmospheres in accordance with the ATEX Directive, with the option of housings painted in your company’s colours.',
        listItems: ["Cast steel housings (up to 350 bar), aluminium housings (up to 250 bar)", "Untreated, burnished, phosphated, anodised or powder-coated", "Stainless steel construction for aggressive media"],
    }

    const CartridgeValve5 = {
        image: imgValveBlock,
        title: 'Valve blocks made precisely to the customer’s wishes',
        description: 'As one of the leading system partners in the field of hydraulics, HANSA‑FLEX has outstanding engineering know-how about the construction of valve blocks. Customers can therefore depend on receiving valve blocks designed and manufactured precisely to meet their technical requirements.',
        listItems: ["Customer support from specialists", "3D drawings show all the technical details", "Manufactured using state-of-the-art CNC technology"],
    }

    const CartridgeValve6 = {
        image: imgScrewIn,
        listItems: ["2-way and 3-way flow control valves", "2/2-way solenoid-controlled seat valves", "Pressure relief and reducing valves", "Non-return valves", "Overcentre valves", "Flow dividers / combiners"],
    }

    // features card carousel data
    const featuresData = [
        {
            title: "Versatile and efficient",
            description: "Valve blocks with screw-in cartridge valves offer maximum flexibility in the minimum space.",
            image: imgMark
        },
        {
            title: "High product quality",
            description: "HANSA‑FLEX sells high-quality screw-in cartridge valves from well-known manufacturers.",
            image: imgMark
        },
        {
            title: "Maximum availability",
            description: "Thanks to its large warehouse capacity, HANSA‑FLEX can supply products reliably and at short notice.",
            image: imgMark
        },
        {
            title: "Individualised housings",
            description: "Would you like valve housings in your company colours? That’s no problem at HANSA‑FLEX",
            image: imgMark
        },
        // Add more objects for additional FeaturesCard data
    ];

    //HfTab data
    const imageTextData = [
        {
            title: "Replacement solenoid coils for valves",
            description: "These DC or AC solenoid coils are capable of high switching performances and available from stock. Order now from the HANSA‑FLEX online shop!",
            listItems: ["Rated voltages 12, 25 and 230 V", "Power consumption 22 and 25 W"],
            buttonName: "ALL SOLENOID COILS",
            buttonLink: 'https://shop.hansa-flex.in/en_GB/c/webcat_HF_HKO_0570_2380_6310',
            image: imgReplaceSolenoid
        },
        {
            title: "Intermediate plates for complex functions",
            description: "In addition to cartridge valves, fluid technology specialist HANSA‑FLEX also supplies CETOP intermediate plates to provide complex switching functions in your systems.",
            listItems: ["Intermediate plates and ready-to-install plate mounting valves", "Stacked valves", "Perfectly designed for iso standard bores"],
            buttonName: "READ MORE",
            buttonLink: '/cetop-valve',
            image: imgIntermediatePlate
        },
        {
            title: "Seals for all areas of use",
            description: "In addition to NBR seals, we can also supply other sealing materials for cartridge valves on request. These include seals suitable for use at higher temperatures or with aggressive media.",
            listItems: ["Seals for high-temperature applications", "Seals for use with aggressive media"],
            buttonName: "ORDER NOW",
            buttonLink: '#contact-us-section',
            image: imgSeals
        },
    ];

    const tabTitles = [
        { title: "Replacement solenoid coils", eventKey: "Replacement solenoid coils" },
        { title: "Intermediate plates", eventKey: "Intermediate plates" },
        { title: "Seals", eventKey: "Seals" },
    ];


    return (
        <div>
            <div>
                <CenterStage
                    imageSrc={imgStage}
                    title={"SCREW-IN CARTRIDGE VALVES FOR HYDRAULIC CONTROL BLOCKS"}
                    paragraph={"Cartridge valves are screwed directly into the valve blocks or valve housings. This allows complex fluid controls to be combined and fitted into the smallest space. Until now, screw-in cartridge valves were used mainly in mobile hydraulics. However, they are now also used in stationary hydraulic systems because their increasingly compact designs have benefits above all in the field of automation."}
                />
            </div>

            <div>
                <SectionHeader
                    title={"MORE CONTROL FLEXIBILITY IN THE SMALLEST SPACE"}
                />
                <ImageText
                    data={CartridgeValve1} />

                <ImageText
                    data={CartridgeValve2}
                    left={false} />
            </div>

            <div>
                <SectionHeader
                    title={"SCREW-IN CARTRIDGE VALVES FROM HANSA‑FLEX"}
                    subtitle={"HANSA‑FLEX can provide a large selection of high-quality screw-in cartridge valves with various functions and all the associated components from the same provider."}
                />
                <FeatureCardCarousel
                    featuresData={featuresData} />
            </div>

            <div>
                <SectionHeader
                    title={"SCREW-IN CARTRIDGE VALVES IN THE HANSA‑FLEX ONLINE SHOP"}
                    subtitle={"HANSA‑FLEX has an extensive range of screw-in cartridge valves for applications in mobile and stationary hydraulics. Find suitable valves for your required switching functions in our online shop. Our fluid technology experts are always delighted to advise you."}
                />
            </div>

            <div>
                <SectionHeader
                    title={"EFFICIENT COMPONENTS AND TAILOR-MADE SOLUTIONS"}
                />
                <ImageText
                    data={CartridgeValve3} />
                <ImageText
                    data={CartridgeValve4}
                    left={false} />
                <ImageText
                    data={CartridgeValve5} />
            </div>

            <div>
                <SectionHeader
                    title={"SCREW-IN CARTRIDGE VALVES IN THE HANSA‑FLEX PRODUCT RANGE"}
                    subtitle={"At HANSA‑FLEX, customers have a large choice of screw-in cartridge valves for providing a wide range of functions in numerous designs and variants."}
                />
                <ImageText
                    data={CartridgeValve6} />
            </div>

            <div>
                <SectionHeader
                    title={"HANSA‑FLEX PIPELINE INSTALLATION VALVES FOR ALL APPLICATIONS"}
                />
                <HFTabs imageTextData={imageTextData} tabTitles={tabTitles} />
            </div>

            <div>
                <SectionHeader
                    title={"THESE TOPICS MAY ALSO BE OF INTEREST TO YOU"}
                    subtitle={"Further products, services and interesting topics concerning valve technology from HANSA‑FLEX."}
                />
                <div>
                    <Container className="custom-container">
                        <Row>
                            <Col sm={12} md={6} lg={4} ><ImageCard image={imgReliable} learnMoreLink={"/assembly-and-installation"}
                                title="Reliable hydraulic experts available on the spot"
                                desc={"The HANSA‑FLEX Industrial Service installs screw-in cartridge valves and ensures systems operate reliably in the long term."} /></Col>

                            <Col sm={12} md={6} lg={4} ><ImageCard image={imgIndividualised} learnMoreLink={"/Hydraulicunit"}
                                title="Individualised solutions for valve blocks"
                                desc={"In consultation with you and after an extensive needs analysis, HANSA‑FLEX manufactures your control components exactly to meet your requirements."} /></Col>

                            <Col sm={12} md={6} lg={4} ><ImageCard image={imgValveTech} learnMoreLink={"/valve-tech"}
                                title="Valve technology at the highest levels of quality"
                                desc={"With valve technology from HANSA‑FLEX, you gain from high-grade materials, robust construction and long service life."} /></Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </div>
    )
}

export default CartridgeValves