import React, { useEffect } from 'react';
import imgPerformedHoses from '../../../assets/Products/HosesAndHoseLines/performed Hoses/performedHoses.jpg'
import CenterStage from '../../../Components/CenterStage/CenterStage';
import imgHFfrom from '../../../assets/Products/HosesAndHoseLines/performed Hoses/HFfrom.jpg'
import imgIndividual from '../../../assets/Products/HosesAndHoseLines/performed Hoses/individual.jpg'
import SectionHeader from '../../../Components/SectionHeader/SectionHeader';
import ImageText from '../../../Components/ImageText/ImageText';
import imgMark from '../../../assets/mark.png';
import FeatureCardCarousel from '../../../Components/FeaturesCardCarousal/FeatureCardCarousal';
import imgHighPerformance from '../../../assets/Products/HosesAndHoseLines/performed Hoses/highPerformance.jpg'
import imgPreformedHoseRequirement from '../../../assets/Products/HosesAndHoseLines/performed Hoses/PreformedHoseRequirement.jpg'
import imgMadeToMeasure from '../../../assets/Products/HosesAndHoseLines/performed Hoses/MadeToMeasure.jpg'
import imgHoseForApplication from '../../../assets/Products/HosesAndHoseLines/performed Hoses/HoseForApplication.jpg'
import imgXtrHighPressureHose from '../../../assets/Products/HosesAndHoseLines/performed Hoses/XtrHighPressureHose.jpg'
import { Col, Container, Row } from 'react-bootstrap';
import ImageCard from '../../../Components/ImageCard/ImageCard';



function PerformedHoses() {

    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
    }, []);

    const MadeTOMeasure1 = {
        image: imgHFfrom,
        title: 'HANSA-FLEX from conception to preparation for serial production',
        description: 'HANSA-FLEX has a wide portfolio of standard pre-formed hoses and offers complete solutions for customers who need individually manufactured pre-formed hoses according to their specific requirements:',
        listItems: ["Designed by the HANSA-FLEX engineering team", "From prototyping to serial production", "Optional digital determination of XYZ data"]
    }

    const MadeToMesure2 = {
        image: imgIndividual,
        title: 'Individual pre-formed hoses for all geometries',
        description: 'Pre-formed hoses must be designed and manufactured perfectly to fit the intended installation situation. Therefore, the first step is to manufacture molded mandrels based on the required final geometry of the hose in steel or stainless steel. The material for the pre-formed hose is then wound or stretched and assumes the exact geometry of the molded mandrel. Multiple layers can be applied so that, for example, the inner layer of the pre-formed hose meets the medium resistance requirements specified by the customer. Pre-formed hoses can be reinforced with textile or metal braids. Metal components can be vulcanized into the layers or rubber components can be vulcanized onto them to allow even more hose design possibilities and fixing arrangements.',
    }

    const MadeToMesure3 = {
        image: imgPreformedHoseRequirement,
        listItems: ["Customized solutions for almost all bending degrees", "Extruded or wound on a mandrel to fit specific application", "Reinforced with polyester or rayon fabric inserts", "Pre-formed hoses can incorporate multiple inlets/outlets"]
    }

    const HighPerformance1 = {
        image: imgHighPerformance,
        title: 'High-performance pre-formed hoses',
        description: 'HANSA-FLEX installs pre-formed hoses for fuel supply, cooling water, and turbocharger air on the 320 hp Marine Hypercraft jet ski manufactured by Belassi. Pre-formed hoses not only have to withstand high temperatures directly in the engine, corrosive saltwater, and solar radiation but also the mechanical loads applied to them during travel. Speeds of up to 120 km/h and jumps of up to 5 m in height pose special demands on the hoses.',
    }

    const performedHosesFrom = [
        {
            title: "Free form",
            description: "HANSA-FLEX produces individual solutions with curves of almost all radii.",
            image: imgMark
        },
        {
            title: "High load resistance",
            description: "Reinforcement layers can be incorporated for high-pressure or vacuum applications upon request.",
            image: imgMark
        },
        {
            title: "Resistance to high temperatures",
            description: "Operating temperature ranges from -40°C to +320°C, depending on the material mix.",
            image: imgMark
        },
        {
            title: "Material mix qualities confirmed in tests",
            description: "For example, mixes certified according to DIN 73411 or fire protection standards DIN 5510 / EN 45545.",
            image: imgMark
        },

        // Add more objects for additional FeaturesCard data
    ];

    return (
        <div>
            <div>
                <CenterStage
                    imageSrc={imgPerformedHoses}
                    title={"HANSA-FLEX PRE-FORMED HOSES FOR HIGH FLEXIBILITY IN TIGHTEST SPACES"}
                    paragraph={"Pre-molded hoses are generally required when conventional hoses cannot be used due to limited installation space. For example, in situations where a hose carrying fuel or coolant in engines or batteries would have to be sharply bent, HANSA-FLEX pre-formed hoses constitute an effective and economical solution. They are highly elastic, very robust, and can be manufactured in almost any shape."}
                />
            </div>

            <div>
                <SectionHeader
                    title={"CUSTOM-MADE PRE-FORMED HOSES FROM HANSA-FLEX"}
                />
                <div>
                    <ImageText
                        data={MadeTOMeasure1} />
                    <ImageText
                        data={MadeToMesure2}
                        left={false} />
                </div>
            </div>

            <div>
                <div>
                    <SectionHeader
                        title={"HANSA-FLEX PRE-FORMED HOSES, YOUR PARTNER FOR INNOVATIVE SYSTEMS"}
                        subtitle={"HANSA-FLEX experts, with their years of experience and comprehensive industry knowledge, can always determine the perfect shape and material mix for any complex customer requirement."}
                    />

                    <FeatureCardCarousel
                        featuresData={performedHosesFrom} />
                </div>
            </div>

            {/* TODO: Carousal */}
            {/* TODO: Client Review */}

            <div>
                <ImageText
                    data={HighPerformance1} />
            </div>

            <div>
                <SectionHeader
                    title={"PRECISELY TAILORED PRE-FORMED HOSES TO CUSTOMER NEEDS"}
                    subtitle={"HANSA-FLEX perfectly adapts customer-ordered pre-molded hoses to dimensions, material requirements, and field of application."}
                />
                <ImageText
                    data={MadeToMesure3}
                    left={false}
                />
            </div>

            <div>
                <SectionHeader
                    title={"THESE HANSA-FLEX PRODUCTS MAY ALSO BE OF INTEREST TO YOU"}
                />
                <div>
                    <Container className="custom-container">
                        <Row>
                            <Col sm={12} md={6} lg={4}><ImageCard image={imgMadeToMeasure} learnMoreLink={"/pipelines"}
                                title="Custom-made pipes as single items, small batches, or series"
                                desc={"HANSA-FLEX manufactures application-specific pipes as single items or in batches, with dimensional tolerances according to DIN ISO 2768-1."} /></Col>

                            <Col sm={12} md={6} lg={4}><ImageCard image={imgHoseForApplication} learnMoreLink={"/Industrial-hoses"}
                                title="Industrial hoses for all applications"
                                desc={"HANSA-FLEX's specialized hose workshop produces hoses for all applications. Products are naturally manufactured with the highest quality and delivered quickly."} /></Col>

                            <Col sm={12} md={6} lg={4}><ImageCard image={imgXtrHighPressureHose} learnMoreLink={"/ext-high-pfm-hoses"}
                                title="Extremely high-pressure hoses to meet the highest requirements"
                                desc={"With working pressures of up to 3,200 bar, HANSA-FLEX's extremely high-pressure hoses for hydraulic, water blasting, oil, and gas applications offer safety even under"} /></Col>
                        </Row>
                    </Container>
                </div>
            </div>

        </div>
    );
}

export default PerformedHoses;
