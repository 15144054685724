import React, { useEffect } from 'react';
import HFTab from '../../../Components/HfTabs/HfTabs';
import CenterStage from '../../../Components/CenterStage/CenterStage';
import SectionHeader from '../../../Components/SectionHeader/SectionHeader';
import { Col, Container, Row } from 'react-bootstrap';
import ImageCard from '../../../Components/ImageCard/ImageCard';
import ImageText from '../../../Components/ImageText/ImageText';
import Promoter from '../../../Components/Promoter/Promoter';
import FeatureCardCarousel from '../../../Components/FeaturesCardCarousal/FeatureCardCarousal';
import imgMark from '../../../assets/mark.png';

import Stage from '../../../assets/Services/AssemblyAndInstallation/InstallationOfPipesAndHoseLineSystems/Stage.jpg'
import Comprehensive from '../../../assets/Services/AssemblyAndInstallation/InstallationOfPipesAndHoseLineSystems/Comprehensive.jpg'
import PreciseTime from '../../../assets/Services/AssemblyAndInstallation/InstallationOfPipesAndHoseLineSystems/PreciseTime.jpg'
import Professional from '../../../assets/Services/AssemblyAndInstallation/InstallationOfPipesAndHoseLineSystems/Professional.jpg'
import Safety from '../../../assets/Services/AssemblyAndInstallation/InstallationOfPipesAndHoseLineSystems/Safety.jpg'
import Design from '../../../assets/Services/AssemblyAndInstallation/InstallationOfPipesAndHoseLineSystems/Design.jpg'
import HoseManagement from '../../../assets/Services/AssemblyAndInstallation/InstallationOfPipesAndHoseLineSystems/HoseManagement.jpg'
import Availability from '../../../assets/Services/AssemblyAndInstallation/InstallationOfPipesAndHoseLineSystems/Availability.jpg'
import HFIndustrial from '../../../assets/Services/AssemblyAndInstallation/InstallationOfPipesAndHoseLineSystems/HFIndustrial.jpg'
import HFFluid from '../../../assets/Services/AssemblyAndInstallation/InstallationOfPipesAndHoseLineSystems/HFFluid.jpg'
import Innovation from '../../../assets/Services/AssemblyAndInstallation/InstallationOfPipesAndHoseLineSystems/Innovation.jpg'

const InstallationOfPipeAndHoseLineSystems = () => {
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
    }, []);
    const data1 = {
        image: Comprehensive,
        title: 'Comprehensive assessment and advice',
        description: 'Our hydraulic experts first perform a specialist assessment of the machine or plant, identifying initial potential for optimization, which may relate to positioning, dimensioning, material choice and efficiency, then they make an estimate of the expected cost of the project. If they find excessively worn pipe or hose lines, they look for the reason and develop solutions to remedy the problem. The applicable safety regulations for hydraulic systems are subject to continual amendment. Our experts ensure that their advice will result in hydraulic systems that comply with the law and represent the state-of-the-art in relation to safety and technology.',
    }

    const data2 = {
        image: PreciseTime,
        title: 'Precise time and resource planning',
        description: "Our advisers calculate the time and resources required for the project, taking into account your requirements and suggestions. In cooperation with you, we establish a time window for the work that fits in with your operational requirements. Often there are only a few days in the year when machines or equipment can be completely switched off for maintenance of the hydraulic lines. Therefore, we create a detailed workflow plan and use our years of experience in project management to ensure that milestones and deadlines are met. If the customer wishes, we also undertake the coordination of other trades on large construction sites, so that all the work can be completed efficiently and safely..",
    }

    const data3 = {
        image: Professional,
        title: 'Professional, on-schedule implementation',
        description: 'We can call on our pool of service technicians throughout Germany to implement projects. This means we always have enough staff to cope with peaks of orders in our busy periods or around Christmas and for major projects. Highly qualified personnel perform all our work. We can manufacture customized pipe and hose lines directly on-site in our mobile workshops. HANSA‑FLEX completes orders on an industrial scale requiring large numbers of pipelines in its pipe-bending center. An in-house production area for special fittings manufactures customer-specific solutions flexibly and quickly.',
    }

    const featuresData = [
        {
            title: "Tried and tested designs",
            description: "We optimize the design of our products to make the most of the space available while achieving high efficiency and safety.",
            image: imgMark
        },
        {
            title: "Efficient project management",
            description: "Precise project planning and management ensure we are always on time and within budget.",
            image: imgMark
        },
        {
            title: "Specialists for every task",
            description: "We can draw on specialist staff from across the whole country to handle projects of any size.",
            image: imgMark
        },
        {
            title: "Materials always readily available",
            description: "Components are always available from central warehouses and branches.",
            image: imgMark
        }
    ];


    //HfTab data
    const imageTextData = [
        {
            title: "Reliable health and safety at work",
            description: "Safety at work in the context of hydraulics is a highly dynamic specialist environment. Only experts immersed in the subject on a day-to-day basis can possibly keep up to date. Our hydraulics specialists know the requirements of the current occupational health and safety legislation, in particular the relevant DGUV rules. We have specialists in our company who are familiar with the specific rules of individual industries. They are completely competent in making the correct choice of material in accordance with EN 45545 in the rail sector or implementing the complex DNV GL regulations for maritime applications. We make our specialist knowledge available to you and advise you so that you are always on the safe side.",
            image: Safety
        },
        {
            title: "Focused on the efficiency and durability",
            description: "We not only replace existing pipe and hose lines; we increase the efficiency of your system. We make perfect use of the restricted space available in tight installation situations. We always use the optimum bending radii and avoid twisting the lines. Torsional strain is one of the main reasons for increased wear on hydraulic hose lines. In situations where machines are moving dynamically, we take care to mount hoses perfectly and, if necessary, provide rubbing protection to the hoses. This will increase the reliability and availability of your machine.",
            image: Design
        },
        {
            title: "Find your way through the hose jungle with X-CODE",
            description: "The X-CODE system gives you an overview of your hydraulic hose lines. The six-digit code on the yellow label uniquely identifies the product features of hydraulic connection elements. The X-CODE is not only helpful when completely replacing all the hose lines on a system, but it also makes ordering replacement parts easier from anywhere in the world. You can also store the details of all your hose lines in an easily managed format in the hose management portal My.HANSA‑FLEX. This way you always have a complete overview of upcoming inspection dates and replacement intervals.",
            image: HoseManagement
        },
        {
            title: "Hoses, connections and special parts",
            description: "As Europe’s largest hydraulics equipment dealer and system partner for fluid technology, we have an extensive portfolio of hydraulic components for hoses and pipelines. We are also experts in their installation. With two central warehouses and around 250 branches all over Europe, we can always supply the required components, even in large quantities. Using our international network and our in-house production facilities, we are also in the position to supply special components and manufactured parts at short notice.",
            image: Availability
        },
    ];

    const tabTitles = [
        { title: "Safety", eventKey: "Safety" },
        { title: "Design", eventKey: "Design" },
        { title: "Hose management", eventKey: "Hose management" },
        { title: "Availability", eventKey: "Availability" },
    ];

    return (
        <div>
            <div>
                <CenterStage
                    imageSrc={Stage}
                    title={"PIPE AND HOSE INSTALLATION FOR COMPLETE PLANTS"}
                    paragraph={"The pipes and hoses of hydraulic machines and systems must be replaced regularly for safety reasons alone. But other projects such as increasing efficiency can also be the reason for a complete replacement. With excellently trained service technicians, we complete such orders reliably, to a high standard and also within tight time frames."}
                />
            </div>

            <div>
                <SectionHeader
                    title={'SYSTEM OPTIMISATION BY REGULAR REPLACEMENT OF HYDRAULIC LINES'}
                    subtitle={'Replacing pipe and hose lines on machinery and equipment is not only a question of safety, but it also makes a crucial contribution to optimizing hydraulic systems.'}
                />
                <ImageText
                    data={data1} />
                <ImageText
                    data={data2} left={false} />
                <ImageText
                    data={data3} />
            </div>

            <div>
                <SectionHeader
                    title={"YOUR BENEFITS AT A GLANCE"}
                />
                <FeatureCardCarousel
                    featuresData={featuresData} />
            </div>

            {/* TODO: Carousel */}
            {/* TODO: Client Review */}

            <div>
                <SectionHeader
                    title={"CONTAMINATED FLUID"}
                    subtitle={"These five factors are damaging to hydraulic oil"}
                />
                <HFTab imageTextData={imageTextData} tabTitles={tabTitles} />
            </div>

            <div>
                <SectionHeader
                    title={"THESE TOPICS MAY ALSO BE OF INTEREST TO YOU"}
                />
                <div>
                    <Container className="custom-container">
                        <Row>
                            <Col sm={12} md={6} lg={4}><ImageCard image={HFIndustrial} learnMoreLink={"/assembly-and-installation"}
                                title="HANSA‑FLEX Industrial Service"
                                desc={"Our experienced service technicians in the HANSA‑FLEX Industrial Service team support you with all servicing, maintenance and installation work on mobile and"} /></Col>
                            <Col sm={12} md={6} lg={4}><ImageCard image={HFFluid} learnMoreLink={"/fluid-service"}
                                title="HANSA‑FLEX Fluid Service"
                                desc={"Regular oil care is a key aspect in ensuring trouble-free operation of hydraulic systems and plants."} /></Col>
                            <Col sm={12} md={6} lg={4}><ImageCard image={Innovation} learnMoreLink={"/Engineering"}
                                title="Innovation and manufacture"
                                desc={"As a system partner, HANSA‑FLEX provides you with a full range of engineering services, from system development and design right up to implementation, all from a single"} /></Col>
                        </Row>
                    </Container>
                </div>

            </div>
        </div>
    )
}

export default InstallationOfPipeAndHoseLineSystems