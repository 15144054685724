import React, { useEffect } from 'react'
import CenterStage from '../../Components/CenterStage/CenterStage'
import SectionHeader from '../../Components/SectionHeader/SectionHeader'
import ImageText from '../../Components/ImageText/ImageText'
import FeatureCardCarousel from '../../Components/FeaturesCardCarousal/FeatureCardCarousal'
import HFTabs from '../../Components/HfTabs/HfTabs'
import { Col, Container, Row } from 'react-bootstrap'
import ImageCard from '../../Components/ImageCard/ImageCard'
import imgMark from '../../assets/mark.png'

import imgStage from '../../assets/Products/SealingTechnology/Stage.jpg'
import Minimise from '../../assets/Products/SealingTechnology/Minimise.jpg'
import SpecialTurnedComponents from '../../assets/Products/SealingTechnology/SpecialTurnedComponents.jpg'
import CylinderRepairCeals from '../../assets/Products/SealingTechnology/CylinderRepairCeals.jpg'
import SealTechnology from '../../assets/Products/SealingTechnology/SealTechnology.jpg'
import AssortementBox from '../../assets/Products/SealingTechnology/AssortementBox.jpg'
import HydraulicHose from '../../assets/Products/SealingTechnology/HydraulicHose.jpg'
import HydraulicComponent from '../../assets/Products/SealingTechnology/HydraulicComponent.jpg'
import CylinderRepair from '../../assets/Products/SealingTechnology/CylinderRepair.jpg'


const SealingTechnology = () => {
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
    }, []);

    const sealingTech1 = {
        image: Minimise,
        title: 'Minimise downtimes with HANSA‑FLEX',
        description: 'With ready access to over 11,000 seals, HANSA‑FLEX can usually satisfy most requests immediately. By calling on a number of highly capable partners, HANSA‑FLEX can increase this to 25,000. The majority of orders are dispatched within a few days.',
        listItems: ["Professional advice from experienced experts", "Rapid delivery from extensive stocks", "Immediate manufacture of special seals"],
    }

    const sealingTech2 = {
        image: SpecialTurnedComponents,
        title: 'Special turned components produced quickly and with extreme precision',
        listItems: ["Manufacture of seals with diameters from 5 to 700 mm", "All popular materials such as NBR, HPU, FPM, POM and PTFE available from stock", "Professional advice from experienced staff"],
    }

    const sealingTech3 = {
        image: CylinderRepairCeals,
        title: 'Cylinder repair and seals - all from a single provider',
        description: 'Prompt replacement of wear parts and accessories ensures a long service life for your hydraulic sealingTechs and reduced standstill times for servicing and maintenance.',
        listItems: ["In-house cylinder repair", "Close cooperation between our cylinder workshop and seal department", "Comprehensive specialist advice about the whole process"],
    }

    const sealingTech4 = {
        image: SealTechnology,
        listItems: ["Standard and special seals", "Hydraulic and pneumatic seals", "Static seals", "Sealing rings, flat seals and moulded parts", "Measuring aids for determining the right seal", "Assembly tool"],
    }

    const sealingTech5 = {
        image: AssortementBox,
        title: 'Assortment box with O-rings to AS / BS standards',
        description: 'The handy, robust plastic assortment boxes contain a good selection of high-quality sealing rings in various sizes for a wide range of applications',
        listItems: ["Usable for operating temperatures from -30 °C to 100 °C", "328 O-rings in 30 sizes", "Up to 150 bar (or up to 500 bar with support ring)"],
        buttonName: "TO THE ASSORTMENT BOX",
        buttonLink: "https://shop.hansa-flex.in/en_GB/sealing-equipment/static-seals/o-rings/o-ring-assortments/box-g/p/MAS_BOX_G"
    }

    // features card carousel data
    const featuresData = [
        {
            title: "Extensive range",
            description: "HANSA‑FLEX as an extremely wide range of products from well-known manufacturers",
            image: imgMark
        },
        {
            title: "Professional advice",
            description: "You receive sound technical consultancy services from our highly experienced staff",
            image: imgMark
        },
        {
            title: "Wide range of materials",
            description: "Seals are available in many materials such as NBR, FPM, EPDM, PTFE or PUR",
            image: imgMark
        },
        // Add more objects for additional FeaturesCard data
    ];


    return (
        <div>
            <div>
                <CenterStage
                    imageSrc={imgStage}
                    title={"HANSA‑FLEX HAS THE RIGHT SEAL FOR THE JOB"}
                    paragraph={"Hydraulic and pneumatic systems are only as good as their seals. Just one defective seal can lead to long downtimes and high costs. HANSA‑FLEX helps prevent this with its large stock of seals, expert advice and rapid order processing. If a special design is required, HANSA‑FLEX can manufacture it as a one-off item or a small batch series. You can find a wide choice of seals in the HANSA‑FLEX online shop."}
                />
            </div>

            <div>
                <SectionHeader
                    title={"RAPID DELIVERY, PROFESSIONAL ADVICE AND PRECISE MANUFACTURE"}
                />
                <ImageText
                    data={sealingTech1} />
                <ImageText
                    data={sealingTech2}
                    left={false} />
                <ImageText
                    data={sealingTech3} />
            </div>

            <div>
                <SectionHeader
                    title={"THE HANSA‑FLEX SEAL TECHNOLOGY PRODUCT PORTFOLIO"}
                    subtitle={"HANSA‑FLEX stocks an extensive range of seals and operates a manufacturing centre where special seals can be produced quickly in-house."}
                />
                <ImageText
                    data={sealingTech4} />
            </div>

            <div>
                <SectionHeader
                    title={"HIGH-QUALITY SEALS FROM HANSA‑FLEX"}
                    subtitle={"Investing in high-quality products for sealing technology pays dividends. Seals are a fundamental functional element in fluid technology and are crucial for trouble-free operation."}
                />
                <FeatureCardCarousel
                    featuresData={featuresData} />
            </div>

            <div>
                <SectionHeader
                    title={"HANSA‑FLEX SOLUTIONS FOR MAXIMUM SEALING PERFORMANCE"}
                    subtitle={"Our know-how and passion for sealing technology are reflected in our products. We illustrate this with two selected examples."}
                />
                <ImageText
                    data={sealingTech5} />
            </div>

            <div>
                <SectionHeader
                    title={"THESE TOPICS MAY ALSO BE OF INTEREST TO YOU"}
                />
                <div>
                    <Container className="custom-container">
                        <Row>
                            <Col sm={12} md={6} lg={4} ><ImageCard image={HydraulicHose} learnMoreLink={"/hydraulic-hoses"}
                                title="Hydraulic hoses for all pressure ranges"
                                desc={"HANSA‑FLEX offers a broad spectrum of high-quality hydraulic hoses covering all pressure ranges for use in mobile and stationary hydraulic systems."} /></Col>

                            <Col sm={12} md={6} lg={4} ><ImageCard image={HydraulicComponent} learnMoreLink={"/drive-and-control-tech"}
                                title="Hydraulic components from a complete service provider"
                                desc={"HANSA‑FLEX has a wide range of over 4,500 hydraulic components such as pumps, motors, pressure accumulators, valves, switches and sensors for hydraulic"} /></Col>

                            <Col sm={12} md={6} lg={4} ><ImageCard image={CylinderRepair} learnMoreLink={"/cylinder"}
                                title="Cylinder repair is a cost-efficient alternative"
                                desc={"The HANSA‑FLEX in-house cylinder repair service restores full functionality to the overhauled component. Downtimes are minimised and costs reduced."} /></Col>
                        </Row>
                    </Container>
                </div>
            </div>

        </div>
    )
}


export default SealingTechnology