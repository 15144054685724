import React, { useEffect } from 'react';
import CenterStage from '../../../../Components/CenterStage/CenterStage';
import HFTab from '../../../../Components/HfTabs/HfTabs';
import imgHoseManagement from '../../../../assets/Services/HoseManagement/hosemanagement.jpg';
import SectionHeader from '../../../../Components/SectionHeader/SectionHeader';
import { Col, Container, Row } from 'react-bootstrap';
import ImageCard from '../../../../Components/ImageCard/ImageCard';
import Promoter from '../../../../Components/Promoter/Promoter';
import imgTabletMobile from '../../../../assets/HosesAndHoseLines/HosesAndHoseLine/tabletMobile.png'
import ImageText from '../../../../Components/ImageText/ImageText';
import FeatureCardCarousel from '../../../../Components/FeaturesCardCarousal/FeatureCardCarousal';
import imgMark from '../../../../assets/mark.png';

import imgFulfillRequirement from '../../../../assets/Services/HoseManagement/fulfil_all_requirements.jpg'
import imgPlanInspection from '../../../../assets/Services/HoseManagement/plan_inspection.jpg'
import imgHoseCoding from '../../../../assets/Services/HoseManagement/x_code_hose_coding.jpg'
import imgHoseSoftware from '../../../../assets/Services/HoseManagement/hose_management_software.jpg'
import imgCertifiedTechnicians from '../../../../assets/Services/HoseManagement/certified_service_technicians.jpg'
import imgCoding from '../../../../assets/Services/HoseManagement/coding.jpg'
import imgInstalation from '../../../../assets/Services/HoseManagement/installation.jpg'
import imgServicingPlanning from '../../../../assets/Services/HoseManagement/servicingPlanning.jpg'
import imgDocumentation from '../../../../assets/Services/HoseManagement/documentation.jpg'
import imgHoseFixedCost from '../../../../assets/Services/HoseManagement/Hose_management_services_fixed_cose.jpg'
import imgProceduralRegulation from '../../../../assets/Services/HoseManagement/ProceduralRegulation.jpg'
import imgLegalRequirement from '../../../../assets/Services/HoseManagement/LegalRequirement.jpg'
import imginspection from '../../../../assets/Services/HoseManagement/inspection.jpg'

//PAGE COMPLETE

const HoseManagement = () => {

    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
    }, []);

    //ImageText Data
    const hoseManagement1 = {
        image: imgFulfillRequirement,
        title: 'Reliably fulfill all legal requirements',
        description: 'The employer is personally responsible: The precise handling of hydraulic hoses is defined in the EU Directive on the use of work equipment 2009/104/EC, the German Industrial Safety Act (BetrSichV), and other laws. The employer is obliged to assess the potential risks of hydraulic hoses and to take appropriate mitigation measures, to conduct regular inspections, and to keep records. HANSA-FLEX hose management assists in these tasks.'
    }

    const hoseManagement2 = {
        image: imgPlanInspection,
        title: 'Efficiently plan and professionally conduct inspections',
        description: 'Unique marking, digital capture, efficient management, and legally compliant documentation of all in-use hose lines mean that all data is available and just a few mouse clicks away. With this information, maintenance can be efficiently planned, executed at the right time, and recorded. HANSA-FLEX supports you in planning your processes and their implementation as part of your normal daily activities - from providing individual service measures to complete worry-free packages.'
    }

    const hoseManagement3 = {
        image: imgHoseFixedCost,
        title: 'Fixed-price hose management services',
        description: 'Our four service packages: Basic, Plus, Premium, and Pro contain various services for your hose management. The individual packages build on each other and ensure maximum planning security for a fixed daily price. Use our hose management service package to simplify and improve the maintenance of your hydraulic systems. The range of packages ranges from free use of the X-CODE Manager software (Basic), through data package and digitization (Plus), to the complete worry-free package, including inspection and documentation according to BetrSichV (Premium). We also include extra portion service (Pro).',
        buttonName: "VIEW SERVICE PACKAGES",
        buttonLink: "/service-packages"
    }

    // features card carousel data
    const featuresData = [
        {
            title: "Increased safety",
            description: "Reduces accidents and ensures workplace safety for equipment users.",
            image: imgMark
        },
        {
            title: "Improved legal compliance",
            description: "Adhere to maintenance intervals and record inspections in compliance with the law.",
            image: imgMark
        },
        {
            title: "Reduced downtime",
            description: "Avoids unplanned machine downtime and reduces costs by preventing component wear.",
            image: imgMark
        },
        {
            title: "More transparency",
            description: "Access all information about your hose lines, machinery, and maintenance dates at any time.",
            image: imgMark
        },
    ];

    //HfTab data 1
    const imageTextData = [
        {
            title: "Manufacturing, coding, and digital profile",
            description: "We pre-assemble the hose line specifically for your application and give it the unmistakable X-CODE hose code. The X-CODE forms the basis for hose management and for creating digital profiles with all relevant information. Existing hoses without X-CODE can be coded later through follow-up coding by our Industrial Service, so they can be included in the hose management system.",
            image: imgCoding
        },
        {
            title: "Installation and digital allocation to machines",
            description: "HANSA-FLEX Industrial Service performs professional installation of your pipes for you. The digital profile of each hose line is assigned in the X-CODE Manager hose management software directly to the respective machine or component. You can represent all your machines and equipment in the X-CODE Manager and structure the information according to your own criteria, in order to obtain the best overview of all hydraulic pipes related to your locations, machines, and components.",
            image: imgInstalation
        },
        {
            title: "Risk analysis and classification",
            description: "Unfortunately, we cannot conduct your risk assessment for you, but we can significantly help you prepare it. A risk analysis forms the basis for assessing the risk of each hose line by the machine operator. The risk assessment determines the classification, the appropriate maintenance plan, and the deadlines for legally prescribed visual inspections.",
            image: imgServicingPlanning
        },
        {
            title: "Visual inspection and legally compliant documentation",
            description: "Legally prescribed visual inspections must be carried out on all pipes by an officially authorized inspector and documented in compliance with the law. An inspection that has not been properly documented is considered not to have been carried out. We can perform all inspections and documentation for you. One form of documentation is a digital inspection record.",
            image: imgDocumentation
        },
    ];

    const tabTitles = [
        { title: "Coding", eventKey: "Coding" },
        { title: "Installation", eventKey: "Installation" },
        { title: "Maintenance planning", eventKey: "Maintenance planning" },
        { title: "Documentation", eventKey: "Documentation" },
    ];

    //HfTab data 2
    const imageTextData2 = [
        {
            title: "European and national legal requirements",
            description: "A series of European and national legislation governs the safe and legally compliant handling of hose lines. For example, the German Industrial Safety Act (BetrSichV) obliges employers to carry out risk assessments of their work equipment, to conduct regular inspections, and to record the results.",
            image: imgLegalRequirement
        },
        {
            title: "Procedural regulations for employers",
            description: "National regulations on occupational health and safety, as well as technical information, rules, and standards, clarify these duties and formulate practical procedural rules for employers. These include correct use, systematic inspection, and planned replacement of pipes.",
            image: imgProceduralRegulation
        },
        {
            title: "Inspection according to BetrSichV and DGUV R",
            description: 'Only "officially authorized persons for inspection" can carry out inspections of pipes (§14 BetrSichV). The type and scope of the inspections are defined by the risk assessment, further information can be found in DGUV rule 113-020. By registering the inspection and its results, the employer fulfills the legal obligation to document the inspection (§6 ArbSchG and §14 BetrSichV).',
            image: imginspection
        },
    ];

    const tabTitles2 = [
        { title: "Legal requirements", eventKey: "Legal requirements" },
        { title: "Procedural regulations", eventKey: "Procedural regulations" },
        { title: "Inspections", eventKey: "Inspections" },
    ];





    return (
        <div>
            <div>
                <CenterStage
                    imageSrc={imgHoseManagement}
                    title={"KEEP YOUR HYDRAULIC SYSTEM OUT OF THE RED ZONE."}
                    paragraph={"Hydraulic flexible tubes are work equipment subjected to great stress. They must be regularly inspected and replaced at the right time to avoid risks to people and machines. Professional hose management provides everything necessary to ensure that these inspections are carried out in a timely manner, within the legally prescribed deadlines, and are recorded."}
                />
            </div>

            <div>
                <SectionHeader
                    title={'HOSE MANAGEMENT MAKES MAINTENANCE EASIER'}
                    subtitle={'Use your hydraulic hoses for as long as it is safe and sensible. With hose management, you are always aware of the legal inspection dates for your work equipment and can plan and perform your future inspections and maintenance at the right time.'}
                />
                <ImageText
                    data={hoseManagement1} />
                <ImageText
                    data={hoseManagement2} left={false} />
            </div>

            <div>
                <SectionHeader
                    title={"360° HOSE MANAGEMENT, FROM MANUFACTURE TO DISPOSAL"}
                />
                <HFTab imageTextData={imageTextData} tabTitles={tabTitles} />
            </div>

            <div>
                <Promoter
                    title={"ORDER IDENTICAL HOSE ASSEMBLIES EVEN FASTER NOW"}
                    checklistItems={["HANSA-FLEX's new hose line configurator allows for even faster and easier ordering of an identical hose line with just two clicks via X-CODE."]}
                    btnLabel1={"Configure now"}
                    btnLabel2={"Learn more"}
                    btnLabel1Link={"https://shop.hansa-flex.in/en_GB/"}
                    btnLabel2Link={"/hose-line-config"}
                    imageUrl={imgTabletMobile}
                />
            </div>

            <div>
                <SectionHeader
                    title={"SAFE AND ECONOMIC"}
                    subtitle={"Hose management pays off. Our services help you operate your mobile and stationary hydraulic systems safely and in compliance with the law. It protects people and machines and saves time and money. Trust in HANSA-FLEX's professional hose management and benefit from:"}
                />

                <FeatureCardCarousel
                    featuresData={featuresData} />
            </div>

            <div>
                <ImageText data={hoseManagement3} />
            </div>

            <div>
                <SectionHeader
                    title={"LEGISLATION GOVERNING HOSE HANDLING"}
                    subtitle={"HANSA-FLEX's hose management services are fully based on current legal requirements of European and national legislation. The services provide you - as an employer, responsible for maintenance, or operator - with constant security!"}
                />
                <HFTab imageTextData={imageTextData2} tabTitles={tabTitles2} />
            </div>

            <div>
                <SectionHeader
                    title={"CODING, SOFTWARE, AND ON-SITE SUPPORT TECHNICIANS"}
                    subtitle={"HANSA-FLEX's hose management services and service packages are based on three elements."}
                />

                <div>
                    <Container className="custom-container">
                        <Row>
                            <Col sm={12} md={6} lg={4}><ImageCard image={imgHoseCoding} learnMoreLink={"/x-code-hose-identification-system"}
                                title="X-CODE Hose Coding"
                                desc={"Utilize extensively tested coding with X-CODE to uniquely identify each hose line."} /></Col>

                            <Col sm={12} md={6} lg={4}><ImageCard image={imgHoseSoftware} learnMoreLink={"/x-code-manager"}
                                title="X-CODE Manager Hose Management Software"
                                desc={"Use the X-CODE Manager to get the best overview of your hose lines, machinery, and maintenance dates."} /></Col>

                            <Col sm={12} md={6} lg={4}><ImageCard image={imgCertifiedTechnicians} learnMoreLink={"/assembly-and-installation"}
                                title="Certified service technicians install and inspect on-site"
                                desc={"Have HANSA-FLEX's Industrial Service experts perform all tasks involved in installing, maintaining, and servicing your hose lines."} /></Col>
                        </Row>

                    </Container>
                </div>

            </div>

        </div>

    )
}

export default HoseManagement