import React, { useEffect } from 'react';
import ImageDescription from '../../Components/ImageDescription/ImageDescription';
import imgBanner from '../../assets/news-events/ADIPEC/banner-blur.png'
import imgBanner2 from '../../assets/news-events/ADIPEC/banner2.png'
import imgCustomerTalk from '../../assets/news-events/ADIPEC/customerTalk.jpeg'
import imgADIPECGroupmain from '../../assets/news-events/ADIPEC/main2.jpg'
import img1 from '../../assets/news-events/ADIPEC/img1.jpeg'
import img2 from '../../assets/news-events/ADIPEC/img2.jpeg'
import img3 from '../../assets/news-events/ADIPEC/img3.jpeg'
import img7 from '../../assets/news-events/ADIPEC/img7.jpg'
import img8 from '../../assets/news-events/ADIPEC/img8.jpg'
import img9 from '../../assets/news-events/ADIPEC/imgR4.jpg'
import img10 from '../../assets/news-events/ADIPEC/imgR5.jpg'
import ThreeDImageCarousel from '../../Components/CircleCarousel/CircleCarousel';
import SectionHeader from '../../Components/SectionHeader/SectionHeader';

const Adipec = () => {
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
    }, []);

    const carouselData = [
        {
            image: imgBanner,
        },
        {
            image: img9
        },
        {
            image: img10,
        },
        // {
        //     image: img1,
        // },
        {
            image: img2,
        },
        // {
        //     image: img3,
        // },

        {
            image: img7,
        },
        {
            image: img8,
        },
        {
            image: imgCustomerTalk
        }
        // Add more items here
    ];


    return (
        <div>
            <ImageDescription imageSrc={imgBanner2} title={"HANSA-FLEX at ADIPEC 2024 in Abu Dhabi"} alignment={'center'}
                paragraphs={["HANSA-FLEX is thrilled to welcome visitors to its stall at ADIPEC 2024, where it will display its specialized hydraulic solutions for the oil and gas sector. Attendees will have the opportunity to explore a comprehensive lineup of high-performance components, including hoses, fuel hoses, metal tubes, and pipe fittings, crafted to withstand demanding industry conditions. These solutions demonstrate HANSA-FLEX’s commitment to providing durable and efficient hydraulic technology, specifically tailored for oil and gas applications.",
                ]}
            />

            <ImageDescription imageSrc={imgADIPECGroupmain} alignment={'center'}
                paragraphs={["At stall no. 14582, located within the Abu Dhabi National Exhibition Centre, HANSA-FLEX has also host interactive demonstrations and one-on-one discussions. Visitors can learn how its port-to-port solutions are designed to enhance operational reliability and reduce maintenance challenges, supporting companies in achieving higher efficiency and safety standards. This hands-on experience enables attendees to discuss how HANSA-FLEX products can be integrated into their operations, fostering collaboration and technological advancement.",
                    "With ADIPEC serving as a global stage, HANSA-FLEX’s presence underscores its role in driving innovation in hydraulic fluid technology. Industry professionals were invited to join HANSA-FLEX from November 4-7, 2024, and see firsthand how the brand’s hydraulic solutions meet the specialized needs of the oil and gas industry."
                ]}
            />

            <SectionHeader title={"ADIPEC at a glance"} />
            <ThreeDImageCarousel items={carouselData} />
        </div>
    );
}

export default Adipec