import React, { useEffect } from 'react';
import ContactUsCard from '../../Components/ContactUsCard/ContactUsCard';
import CenterStage from '../../Components/CenterStage/CenterStage';
import imgStage from '../../assets/ContactUs/c.png'
import imgMHP from '../../assets/ContactUs/MHP3.png'
import imgIFP from '../../assets/ContactUs/IFP2.png'
import imgSHP from '../../assets/ContactUs/SHP2.png'
import imgMSO from '../../assets/ContactUs/MSO2.png'

const ContactUs = () => {

    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
    }, []);

    const units = [
        {
            name: "Metal Hose Production Unit (MHP)",
            location: `HANSA-FLEX INDIA PVT. LTD. Plot no. E 3-4, MIDC Phase 3, Chakan Industrial Area, Kuruli & Nighoje, Near Mercedes-Benz, Taluka - Khed, Dist - Pune, Maharashtra - 410501.`,
            telephone: '02135-663939',
            // fax: "jhgyuyuii",
            mail: 'inp@hansa-flex.com',
            image: imgMHP
        },
        {
            name: 'Insert & Ferrules Production Unit (IFP)',
            location: " HANSA-FLEX (IFP) INDIA PVT. LTD. Plot No PAPV95, MIDC, Chakan Industrial Area, Wasuli, Taluka Khed, Pune, Maharashtra – 410507.",
            telephone: '02135-678304',
            // fax: "jhgyuyuii",
            mail: 'inp@hansa-flex.com',
            image: imgIFP
        },
        {
            name: 'Serial Hose Production Unit (SHP) ',
            location: 'HANSA-FLEX INDIA PVT LTD - UNIT II, PLOT NO. E3/5, PHASE 3, MIDC, KURULI, near HP PETROL PUMP, Chakan, Pune, Maharashtra 410501.',
            telephone: '+9714-242-4908',
            // fax: "jhgyuyuii",
            mail: 'uac@hansa-flex.com',
            image: imgSHP
        },
        {
            name: 'Malaysia Sales Office - HANSA-FLEX INDIA (MSO) ',
            location: 'Tower Regency Hotel & Apartments, 6-8, Jln Dato Seri Ahmad Said, 30450 Ipoh, Perak, Malaysia',
            // telephone: '+9714-242-4908',
            // fax: "jhgyuyuii",
            mail: 'mso@hansa-flex.com',
            image: imgMSO
        },
    ];

    return (
        <div className="">
            <div>
                <CenterStage
                    imageSrc={imgStage}
                    title={"Get in touch with HANSA-FLEX India"}
                    paragraph={"Dive into a world of hydraulic expertise and personalised service. Contact us today and see how HANSA-FLEX India can take your hydraulic operations to new heights."} />
            </div>
            <div className=''>
                <ContactUsCard units={units} />
            </div>
        </div>
    );
};

export default ContactUs;
